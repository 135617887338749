import { Step, Stepper } from "@mui/material";
import { useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useInfiniteQuery } from "react-query";
import LoadingIcon from "../../../../common/components/LoadingIcon";
import NotFoundBox from "../../../../common/components/NotFoundBox";
import { AGENT_ID_HEADER } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import useParamsLead from "../../../../common/hook/useParamsLead";
import CommunicationCard from "./CommunicationCard";

interface Props {}
const CommunicationsTab = (props: Props) => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();

  const { leadId, agentId } = useParamsLead();

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: [leadId, agentId, "communications"],
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.communications.getListActivity({
            agent_lead_ids: [queryKey[0]],
            agent_ids: [queryKey[1]],
            page_no: pageParam,
          }),
          headers: { [AGENT_ID_HEADER]: agentId },
        })
      );
      return json.data;
    },
    getNextPageParam: (lastPage, pages) => {
      const totalCount = lastPage?.count;
      const totalList = pages?.filter(Boolean)?.reduce((v, c) => {
        return [...v, ...(c?.data || [])];
      }, []);
      if (totalList?.length < totalCount) {
        return lastPage.metadata.page_no + 1;
      }
      return null;
    },
    enabled: !!leadId && !!agentId,
    refetchInterval: 10000,
  });

  const mappedData = useMemo(() => {
    return (data?.pages || [])?.reduce((v, c) => {
      return [...v, ...(c?.data || [])];
    }, []);
  }, [data?.pages]);

  return (
    <>
      <PerfectScrollbar
        style={{
          overflow: "auto",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingLeft: 8,
          paddingBottom: 32,
        }}
        onScroll={(e) => {
          if (
            Math.abs(e.currentTarget.scrollTop) +
              e.currentTarget.offsetHeight +
              500 >=
              e.currentTarget.scrollHeight &&
            !isFetching &&
            hasNextPage
          ) {
            fetchNextPage();
          }
        }}
      >
        <Stepper orientation="vertical">
          {mappedData?.length > 0
            ? mappedData?.map((inbox, index) => {
                return (
                  <Step
                    key={inbox.id + "" + index}
                    active
                    // onClick={(e) => {
                    //   if (currentInbox?.id === inbox?.id) {
                    //     dispatch(setCurrentInbox(undefined));
                    //   } else {
                    //     dispatch(setCurrentInbox(inbox));
                    //   }
                    // }}
                  >
                    <CommunicationCard data={inbox} />
                  </Step>
                );
              })
            : !isFetching && <NotFoundBox />}
        </Stepper>
        {isFetching && <LoadingIcon />}
      </PerfectScrollbar>
    </>
  );
};
export default CommunicationsTab;
