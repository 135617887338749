import { Box, LinearProgress } from "@mui/material";
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useRoutes } from "react-router";
import LoadingIcon from "../../common/components/LoadingIcon";
import { AppState } from "../../redux/store";
import { MAIN_ROUTES } from "../routes";
import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import { mappedRoutesPermission } from "./utils";

const ProcessLoading = React.memo(
  () => {
    const loading = useSelector(
      (state: AppState) => state.common.loading,
      shallowEqual
    );
    if (loading) {
      return (
        <LinearProgress
          sx={{ position: "fixed", top: 0, right: 0, left: 0, zIndex: 100 }}
        />
      );
    }
    return null;
  },
  () => true
);

interface Props {}

const ProjectLayout = (props: Props) => {
  const userData = useSelector((state: AppState) => state.auth.userData);
  const mappedRoutes = mappedRoutesPermission(
    userData?.permissions || [],
    MAIN_ROUTES
  ) as any;

  const element = useRoutes(mappedRoutes);

  return (
    <Box display="flex" bgcolor={"background.default"}>
      <DefaultAside listRouter={mappedRoutes} />
      <Box className="relative flex-1 h-screen">
        <Box className="absolute top-0 h-screen w-screen sm:w-full sm:inset-0  flex-1 flex flex-col overflow-hidden">
          <DefaultHeader />
          <ProcessLoading />
          <React.Suspense fallback={<LoadingIcon />}>{element}</React.Suspense>
        </Box>
      </Box>
      <Box id="aside-right" />
    </Box>
  );
};

export default ProjectLayout;
