import { DeleteRounded, MoreHoriz } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import SchemaForm from "../../SchemaForm";
import { some } from "../../constants";
import DialogCustom from "../DialogCustom";

interface Props {
  data: some;
  selected: boolean;
  onUpdateTag: (value, callback) => Promise<void>;
  onDelete: (value, callback) => void;
}

const ItemCard = (props: Props) => {
  const { data, onUpdateTag, onDelete } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography variant="inherit" flex={1}>
        {data.name}
      </Typography>
      {data.id !== -1 && !data.is_default && (
        <Button
          size="small"
          sx={{ display: "none" }}
          className="more-btn"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
        >
          <MoreHoriz fontSize={"small"} />
        </Button>
      )}
      <DialogCustom
        open={open}
        onClose={() => setOpen(false)}
        title="update"
        fullWidth
        maxWidth="xs"
      >
        <Box p={2}>
          <SchemaForm
            initialData={data}
            onSubmit={async (values) => {
              await onUpdateTag(values, () => setOpen(false));
            }}
            onCancel={() => {
              setOpen(false);
            }}
            schema={{
              fields: {
                name: { mode: "text-field", autoFocus: true },
                delete: {
                  mode: "button",
                  variant: "contained",
                  color: "error",
                  startIcon: <DeleteRounded />,
                  fullWidth: true,
                  children: <FormattedMessage id="delete" />,
                  onClick: () => onDelete(data, () => setOpen(false)),
                  propsWrapper: {
                    xs: true,
                    justifyContent: "flex-end",
                  },
                },
              },
            }}
          />
        </Box>
      </DialogCustom>
    </>
  );
};

export default ItemCard;
