import {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ConfirmDialog, { ConfirmDialogParams } from "./ConfirmDialog";

const DUMMY_VALUE = {
  promptConfirmation: async () => {},
};

const ConfirmDialogContext = createContext<ConfirmDialogParams>(DUMMY_VALUE);

export const useConfirmDialog = () => {
  const ref = useContext(ConfirmDialogContext);
  return ref;
};

const ConfirmDialogProvider: FunctionComponent<any> = ({ children }) => {
  const ref = useRef<ConfirmDialogParams>(DUMMY_VALUE);

  const [ref_state, setRefState] = useState<ConfirmDialogParams>(DUMMY_VALUE);

  useEffect(() => {
    if (!ref.current) {
      return;
    } else {
      setRefState(ref.current);
    }
  }, []);

  return (
    <ConfirmDialogContext.Provider value={ref_state}>
      {children}
      <ConfirmDialog ref={ref} />
    </ConfirmDialogContext.Provider>
  );
};
export default ConfirmDialogProvider;
