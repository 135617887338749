import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppState } from "../../redux/store";

const useParamsLead = () => {
  const leadInfo = useSelector(
    (state: AppState) => state.lead.leadDetail,
    shallowEqual
  );
  const { leadId, agentId } = useParams<{ leadId: string; agentId: string }>();
  return {
    leadId,
    agentId: agentId
      ? Number(agentId)
      : leadInfo?.agent_id
      ? leadInfo?.agent_id
      : "",
  };
};

export default useParamsLead;
