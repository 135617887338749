import { isBoolean } from "lodash";
import { some } from "./constants";

export function scrollTo(id: string, offsetTop: number) {
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({ top: el.offsetTop - offsetTop, behavior: "smooth" });
  }
}
export const getErrorMessage = (e: any): any => {
  const message = e?.response?.data?.errors?.map((v) => v.message)?.join(", ");
  return {
    message,
    variant: "error",
  };
};
export const countActiveFilter = (
  filter: some,
  exception?: string[]
): number => {
  const exceptionTmp = [
    "q",
    "page_size",
    "page_no",
    "tab",
    "sort_by",
    "sort_order",
    ...(exception || []),
  ];
  const rs = Object.entries(filter).filter(([key, value]) => {
    if (exceptionTmp.includes(key)) {
      return false;
    }
    if (Array.isArray(value)) {
      if (value?.length > 0) {
        return true;
      }
      return false;
    }
    if (isBoolean(value)) {
      return true;
    }
    return !!value;
  })?.length;

  return rs;
};

export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str?.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export interface IRouteObject {
  caseSensitive?: boolean;
  children?: IRouteObject[];
  icon?: React.ReactNode;
  element?: React.ReactNode;
  index?: boolean;
  hidden?: boolean;
  path?: string;
  absolutePath?: string;
  title?: string;
  portalId?: string;
  collapseWhenIn?: boolean;
  listRole?: string[][];
  breadcrumb?: React.ReactNode;
  blockBreadcrumb?: boolean;
}
