import {
  Autocomplete,
  Box,
  Chip,
  Popover,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { KEYS_QUERY, some } from "../../constants";
import useGeneralHook from "../../hook/useGeneralHook";
import PopperComponentAutocomplete from "../PopperComponentAutocomplete";
import ItemCard from "./ItemCard";

const filter = createFilterOptions<some>();

export interface SelectCustomProps {
  label?: React.ReactNode;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  value?: any[];
  onChange?: (val: any) => void;
  agentId?: string;
}

const TagsSelectBox = (props: SelectCustomProps) => {
  const { label, required, placeholder, error, agentId, onChange } = props;
  const value = props.value || [];
  const {
    intl,
    API_PATHS,
    enqueueSnackbar,
    getErrorMessage,
    dispatch,
    fetchThunk,
    confirmDialog,
  } = useGeneralHook();
  const { promptConfirmation } = confirmDialog;
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    data = [],
    isFetching,
    refetch,
  } = useQuery(
    [agentId, KEYS_QUERY.tags],
    async () => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.tag.getList({}),
        })
      );
      const tmp = json.data.data;
      return tmp;
    },
    { enabled: !!agentId }
  );

  const createTag = async (values) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.tag.create,
          method: "POST",
          data: { name: values },
        })
      );
      refetch();
      enqueueSnackbar({
        message: <FormattedMessage id={"createSuccess"} />,
        variant: json.status,
      });
      onChange && onChange([...value, json.data.data]);
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };
  const onUpdateTag = async (values, callback) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.tag.detail(values.id),
          method: "PUT",
          data: values,
        })
      );
      refetch();
      callback();
      enqueueSnackbar({
        message: <FormattedMessage id={"updateSuccess"} />,
        variant: json.status,
      });
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  const onDelete = async (item, callback) => {
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "deleteConfirm" },
        { name: item?.name }
      ),
      onAccept: async () => {
        try {
          const json = await dispatch(
            fetchThunk({
              url: API_PATHS.tag.detail(item?.id),
              method: "DELETE",
            })
          );
          enqueueSnackbar({
            message: intl.formatMessage({ id: "deleteSuccess" }),
            variant: "success",
          });
          refetch();
          callback();
          onChange &&
            onChange(value?.filter((v) => v.id !== json.data.data?.id));
        } catch (e) {
          enqueueSnackbar({
            message: intl.formatMessage({ id: "deleteFail" }),
            variant: "error",
          });
        }
      },
    });
  };

  return (
    <>
      <TextField
        ref={ref}
        fullWidth
        label={label}
        placeholder={placeholder}
        required={required}
        error={error}
        onClick={() => {
          setOpen(true);
        }}
        InputProps={{
          sx: {
            display: "flex",
            flexWrap: "wrap",
            bgcolor: "background.paper",
          },
          inputProps: { style: { width: "unset" } },
          startAdornment: (
            <>
              {value?.slice(0, 2)?.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    sx={{ margin: "3px" }}
                    label={item.name}
                    onDelete={() => {
                      onChange &&
                        onChange(value?.filter((v) => v.id !== item?.id));
                    }}
                  />
                );
              })}
              {value?.length > 2 && !open && (
                <Typography>+{value?.length - 2}</Typography>
              )}
            </>
          ),
        }}
      />

      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          sx: { width: ref.current?.offsetWidth, bgcolor: "background.paper" },
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            padding: 1,
            fontWeight: 600,
          }}
        >
          {label}
        </Box>
        <Autocomplete
          value={value || []}
          multiple
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                id: -1,
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableCloseOnSelect
          PopperComponent={PopperComponentAutocomplete}
          id="free-solo-tags-picker"
          options={data}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }

            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          // limitTags={2}
          open={true}
          onChange={(e: any, value) => {
            if (e.target.tagName === "BUTTON") {
              return;
            }
            const newData = value.find((v) => v.id === -1);
            if (newData) {
              createTag(newData?.inputValue);
            } else {
              onChange && onChange(value);
            }
          }}
          renderOption={(props, option, state) => {
            return (
              <Box
                component={"li"}
                {...props}
                style={{
                  padding: "0px 8px",
                  display: "flex",
                  alignItems: "center",
                }}
                sx={{
                  height: 40,
                  "&:hover .more-btn": {
                    display: "block",
                  },
                }}
              >
                <ItemCard
                  data={option}
                  selected={state.selected}
                  onUpdateTag={onUpdateTag}
                  onDelete={onDelete}
                />
              </Box>
            );
          }}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          getOptionDisabled={(option) => option.name}
          freeSolo
          fullWidth
          loading={isFetching}
          renderInput={(params) => (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 1,
                fontWeight: 600,
              }}
            >
              <TextField
                {...params}
                fullWidth
                placeholder={placeholder}
                required={required}
                error={error}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    setOpen(false);
                  }
                }}
              />
            </Box>
          )}
        />
      </Popover>
    </>
  );
};

export default TagsSelectBox;
