import { Menu } from "@mui/icons-material";
import { Avatar, Box, Button, InputAdornment } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router";
import FormControlAutoComplete from "../../common/SchemaForm/element/autocomplete/FormControlAutoComplete";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { setOpenAsideBarMobile } from "../../redux/cacheReducer";
import { AppState } from "../../redux/store";
import { AGENT_ROUTES, MAIN_ROUTES } from "../routes";
import DefaultBreadcrumb from "./DefaultBreadcrumb";
import ProfileBox from "./ProfileBox";
import { HEADER_PORTAL_ID, HEADER_TOOLBAR_PORTAL_ID } from "./constants";
interface Props {
  hidedBreadcrumb?: boolean;
}
const AgentHeader = (props: Props) => {
  const { hidedBreadcrumb } = props;
  const { isMobile, API_PATHS, dispatch } = useGeneralHook();
  const navigate = useNavigate();
  const agentData = useSelector(
    (state: AppState) => state.lead.agentDetail,
    shallowEqual
  );
  const agentRoute = useMatch("/agents/:agentId/:tabId/*");

  const selectionBox = (
    <Box className="flex items-center gap-1">
      <FormControlAutoComplete
        value={agentData?.id && agentRoute ? agentData : null}
        sx={{ width: 240, mx: "auto" }}
        inputStyle={{ height: 24 }}
        loadOptions={{
          url: (q) => {
            return API_PATHS.agents.getList({ q, page_no: 0, page_size: 50 });
          },
          mapped: (json) => json.data.data,
        }}
        onChange={(_, value: any) => {
          if (value)
            navigate({
              pathname:
                "/agents/" +
                value?.id +
                "/" +
                (agentRoute?.params.tabId || "contacts"),
            });
          else
            navigate({
              pathname: "/agents",
            });
        }}
        placeholder="Select Agent"
        disableClearable
        startAdornment={
          agentData && (
            <InputAdornment position="start">
              <Avatar
                src={agentData?.photo_url}
                alt="photo_url"
                sx={{ width: 24, height: 24 }}
              />
            </InputAdornment>
          )
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Avatar
              src={option.photo_url}
              alt="photo_url"
              sx={{ width: 32, height: 32, mr: 1 }}
            />
            {option.name}
          </Box>
        )}
      />
      {agentData && (
        <Button
          size="small"
          variant="text"
          onClick={() => {
            navigate({
              pathname: "/agents",
            });
          }}
          sx={{ flexShrink: 0 }}
        >
          <FormattedMessage id="unselect_agent" />
        </Button>
      )}
      {!hidedBreadcrumb && (
        <DefaultBreadcrumb
          routes={[
            {
              path: "agent",
              children: [
                {
                  path: ":agentId",
                  children: AGENT_ROUTES,
                },
              ],
            },
            ...MAIN_ROUTES,
          ]}
          hidden={true}
        />
      )}
    </Box>
  );

  return (
    <>
      <Box
        className="flex flex-col sm:flex-row sm:items-center sm:gap-2 p-2 z-10 sm:py-0 sm:pr-10"
        sx={{
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
          minHeight: 54,
        }}
      >
        {isMobile ? (
          <>
            <Box className="flex items-center gap-2">
              <Button
                variant="outlined"
                sx={{ p: 1, height: 40 }}
                onClick={() => {
                  dispatch(setOpenAsideBarMobile());
                }}
              >
                <Menu />
              </Button>
              {selectionBox}
            </Box>
            <Box
              className="flex items-center flex-1 overflow-auto gap-2 sm:gap-4 h-full"
              id={HEADER_PORTAL_ID}
            />
          </>
        ) : (
          <>
            {selectionBox}
            <Box
              className="flex items-center flex-1 overflow-auto gap-2 sm:gap-4 h-full pr-24"
              id={HEADER_PORTAL_ID}
            />
          </>
        )}
        {!isMobile && <ProfileBox />}
      </Box>
      {isMobile && (
        <Box id={HEADER_TOOLBAR_PORTAL_ID} bgcolor={"background.paper"} />
      )}
    </>
  );
};
export default AgentHeader;
