import { NextPlanRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  Portal,
  Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useMatch, useNavigate } from "react-router-dom";
import useGeneralHook from "../../common/hook/useGeneralHook";
import useParamsLead from "../../common/hook/useParamsLead";
import {
  HEADER_PORTAL_ID,
  HEADER_TOOLBAR_PORTAL_ID,
} from "../../layout/main/constants";
import { AppState } from "../../redux/store";
import LeadFormInfo from "../opportunities/lead-detail/LeadInfoBox/LeadFormInfo";

interface Props {}
const LeadTab = (props: Props) => {
  const { isMobile } = useGeneralHook();
  const navigate = useNavigate();
  const selectionLeads = useSelector(
    (state: AppState) => state.selection.selectionLeads,
    shallowEqual
  );

  const { leadId } = useParamsLead();
  const selectionIds = Object.keys(selectionLeads);
  const index = selectionIds.findIndex((id) => id === leadId);

  const matchRouter = useMatch("/leads/:leadId/:tab/*");

  const LIST_TABS = [
    {
      hidden: index === -1 || selectionIds?.length === 1,
      component: (
        <Button
          component={"a"}
          variant="outlined"
          size="small"
          className="p-1 sm:px-4 sm:py-2"
          disabled={index === 0}
          sx={{ position: "sticky", left: 0, zIndex: 10 }}
          onClick={() => {
            const preData = selectionLeads[selectionIds[index - 1]];
            if (preData)
              navigate(
                "/leads/" +
                  preData?.id +
                  "/" +
                  preData?.agent_id +
                  "/" +
                  matchRouter?.params?.tab +
                  (matchRouter?.params?.["*"]
                    ? "/" + matchRouter?.params?.["*"]
                    : "")
              );
          }}
        >
          <NextPlanRounded sx={{ transform: "scale(-1, 1)" }} />
        </Button>
      ),
    },

    {
      value: "communications",
    },
    {
      value: "activities",
    },
    {
      hidden: index === -1 || selectionIds?.length === 1,
      component: (
        <Button
          component={"a"}
          variant="outlined"
          size="small"
          className="p-1 sm:px-4 sm:py-2"
          disabled={index === selectionIds?.length - 1}
          sx={{ position: "sticky", right: 0, zIndex: 10 }}
          onClick={() => {
            const nextData = selectionLeads[selectionIds[index + 1]];
            if (nextData)
              navigate(
                "/leads/" +
                  nextData?.id +
                  "/" +
                  nextData?.agent_id +
                  "/" +
                  matchRouter?.params?.tab +
                  (matchRouter?.params?.["*"]
                    ? "/" + matchRouter?.params?.["*"]
                    : "")
              );
          }}
        >
          <NextPlanRounded />
        </Button>
      ),
    },
  ];

  const tabs = (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        py: 1,
      }}
    >
      {index !== -1 && !isMobile && (
        <Typography variant="subtitle1">
          {index + 1}/{selectionIds?.length}&nbsp;
          <FormattedMessage id="leads" />
        </Typography>
      )}
      <ButtonGroup variant="outlined" className="flex overflow-auto">
        {LIST_TABS.map((item, index) => {
          if (item.hidden) {
            return null;
          }
          if (item.component) {
            return (
              <React.Fragment key={index}>{item.component}</React.Fragment>
            );
          }
          return (
            <Button
              component={Link}
              to={{ pathname: item.value }}
              key={index}
              variant={
                matchRouter?.params?.tab === item.value
                  ? "contained"
                  : "outlined"
              }
              size="small"
              className="p-1 sm:px-4 sm:py-2"
            >
              <FormattedMessage id={item.value} />
            </Button>
          );
        })}
      </ButtonGroup>
      {index !== -1 && !isMobile && <Box sx={{ width: 80 }} />}
    </Box>
  );
  if (isMobile) {
    return (
      <>
        {/* <Portal container={document.getElementById(HEADER_PORTAL_ID)}>
          {slectionBox}
        </Portal> */}
        <Portal container={document.getElementById(HEADER_TOOLBAR_PORTAL_ID)}>
          {tabs}
        </Portal>
        {index !== -1 && (
          <Paper sx={{ height: 160, overflow: "auto" }}>
            <LeadFormInfo />
          </Paper>
        )}
      </>
    );
  }
  return (
    <Portal container={document.getElementById(HEADER_PORTAL_ID)}>
      <Box className="flex items-center flex-1 p-2 flex-col gap-2 sm:flex-row sm:p-0">
        {tabs}
      </Box>
    </Portal>
  );
};
export default LeadTab;
