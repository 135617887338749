import query from "query-string";
import { some } from "../common/constants";

// `npm run start` = process.env.NODE_ENV=development
// `npm run build` = process.env.NODE_ENV=production
// Preview & Production deployments, vercel runs `next build` = process.env.NODE_ENV=production
export const development = process.env.NODE_ENV === "development";
export const GOMEDIA_BACKEND_BASE_URL =
  process.env.REACT_APP_GOMEDIA_BACKEND_BASE_URL;

const GMBackendBaseUrl = development ? "/api/" : GOMEDIA_BACKEND_BASE_URL;
export const API_PATHS = {
  gen: (url, q?: some) =>
    `${GMBackendBaseUrl}${url}?${query.stringify(q || {}, {
      arrayFormat: "comma",
      skipEmptyString: true,
      skipNull: true,
    })}`,
  genDetail: (url, id) => `${GMBackendBaseUrl}${url}/${id}`,
  communications: {
    getListActivity: (params: some) =>
      `${GMBackendBaseUrl}/api/communications-activities?${query.stringify(
        params,
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/communications?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/communications/${id}`,
    call: (params: some) =>
      `${GMBackendBaseUrl}/api/calls?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    email: (params: some) =>
      `${GMBackendBaseUrl}/api/emails?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id) => `${GMBackendBaseUrl}/api/communications/${id}`,
  },
  activities: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/admin/activities?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
  },
  auth: {
    verify: (params) => `${GMBackendBaseUrl}/auth/ga/verify${params}`,
    login: `${GMBackendBaseUrl}/auth/login`,
    logout: `${GMBackendBaseUrl}/auth/logout`,
    me: `${GMBackendBaseUrl}/auth/me`,
  },
  auth_facebook: {
    verify: (params) =>
      `${GMBackendBaseUrl}/api/agents/fb/auth/verify${params}`,
    login: `${GMBackendBaseUrl}/api/agents/fb/auth`,
  },
  agents: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/admin/agents?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id: string | number) => `${GMBackendBaseUrl}/admin/agents/${id}`,
    create: `${GMBackendBaseUrl}/admin/agents`,
    bulkForceLogout: `${GMBackendBaseUrl}/admin/agents/bulk-force-logout`,
  },
  agents_leads: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/agents-leads?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/agents-leads/${id}`,
    create: `${GMBackendBaseUrl}/api/agents-leads`,
    getListGrouped: (params: some) =>
      `${GMBackendBaseUrl}/api/agents-leads/grouped?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    bulk: `${GMBackendBaseUrl}/api/agents-leads/bulk`,
    bulkStage: `${GMBackendBaseUrl}/api/agents-leads/bulk-stage`,
    bulkTags: `${GMBackendBaseUrl}/api/agents-leads/bulk-tags`,
    bulkHardDelete: `${GMBackendBaseUrl}/api/agents-leads/bulk-hard-delete`,
  },

  stages: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/stages?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/stages/${id}`,
    create: `${GMBackendBaseUrl}/api/stages`,
  },

  manage_facebook: {
    getListCampaign: (params: some) =>
      `${GMBackendBaseUrl}/api/agents/fb/campaigns?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`, //get list of agent facobook campaign
  },
  pipelines: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/pipelines?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/pipelines/${id}`,
    create: `${GMBackendBaseUrl}/api/pipelines`,
  },
  smartlist: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/smart-lists?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/smart-lists/${id}`,
    create: `${GMBackendBaseUrl}/api/smart-lists`,
    getFilter: `${GMBackendBaseUrl}/api/smart-list-filters`,
  },
  email: {
    send: `${GMBackendBaseUrl}/api/emails`,
    attachments: (params: some) =>
      `${GMBackendBaseUrl}/api/emails/attachments?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    templateDetail: (id) => `${GMBackendBaseUrl}/api/emails/templates/${id}`,
    create: `${GMBackendBaseUrl}/api/emails/templates`,
  },
  tag: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/tags?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/tags/${id}`,
    create: `${GMBackendBaseUrl}/api/tags`,
  },

  reports: {
    breakdown: (params: some) =>
      `${GMBackendBaseUrl}/api/reports?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    calls: (params: some) =>
      `${GMBackendBaseUrl}/api/reports/calls?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    emails: (params: some) =>
      `${GMBackendBaseUrl}/api/reports/emails?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    leads: (params: some) =>
      `${GMBackendBaseUrl}/api/reports/leads?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    whatsapp: (params: some) =>
      `${GMBackendBaseUrl}/api/reports/whatsapp-messages?${query.stringify(
        params,
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
  },
  templates: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/templates?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    getCampaigns: (params: some) =>
      `${GMBackendBaseUrl}/api/campaigns?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
  },
  whatsapp: {
    detail: (id) => `${GMBackendBaseUrl}/api/whatsapp/templates/${id}`,
    create: `${GMBackendBaseUrl}/api/whatsapp/templates`,
    channels: (params: some) =>
      `${GMBackendBaseUrl}/admin/whatsapp/channels?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    cursorMessages: (params: some) =>
      `${GMBackendBaseUrl}/api/whatsapp/messages?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detailMessage: (id, agent_lead_id) =>
      `${GMBackendBaseUrl}/api/whatsapp/messages/${id}?agent_lead_id=${agent_lead_id}`,
    send: `${GMBackendBaseUrl}/api/whatsapp/messages`,
    upload: `${GMBackendBaseUrl}/api/whatsapp/upload`,
  },
  blast: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/blasts?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    detail: (id) => `${GMBackendBaseUrl}/api/blasts/${id}`,
    create: `${GMBackendBaseUrl}/api/blasts`,
  },
  calendar: `${GMBackendBaseUrl}/api/calendar-events`,
  aircall: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/calls-numbers?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
  },
  tracking: {
    getList: (params: some) =>
      `${GMBackendBaseUrl}/api/tracking/events?${query.stringify(params, {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
  },
  automations: {
    bulkStart: `${GMBackendBaseUrl}/api/automations/bulk-start`,
    bulkResume: `${GMBackendBaseUrl}/api/automations/bulk-resume`,
    bulkPause: `${GMBackendBaseUrl}/api/automations/bulk-pause`,
    bulkStop: `${GMBackendBaseUrl}/api/automations/bulk-stop`,
  },
};
