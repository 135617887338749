import { ArrowUpwardRounded } from "@mui/icons-material";
import { Box, Button, Collapse, Divider, Typography } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store";

interface Props {}

const EventCard = ({ data }) => {
  return (
    <Box component={"li"}>
      <Typography variant="subtitle2">{data.summary}</Typography>
      <Box>
        <Typography variant="body2">
          <FormattedMessage id="from" />
          :&nbsp;
          {data.date_start ? dayjs(data.date_start).format("LL") : "-"}
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="to" />
          :&nbsp;
          {data.date_end ? dayjs(data.date_end).format("LL") : "-"}
          &nbsp;({dayjs(data.date_end).diff(dayjs(data.date_start), "minutes")}
          minutes)
        </Typography>
        {data.location && (
          <Typography variant="body2">
            <FormattedMessage id="location" />
            :&nbsp;
            {data.location}
          </Typography>
        )}
        {data.description && (
          <Typography
            variant="body2"
            component={"div"}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            <FormattedMessage id="description" />
            :&nbsp;
            <Typography
              component={"div"}
              variant="inherit"
              sx={{ "& *": { fontSize: 12 } }}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </Typography>
        )}
      </Box>
    </Box>
  );
};
const EventsBox: React.FunctionComponent<Props> = (props) => {
  const leadDetail = useSelector(
    (state: AppState) => state.lead.leadDetail,
    shallowEqual
  );
  const [collapse, setCollapse] = React.useState(false);

  if (!leadDetail?.calendar_events?.length) {
    return null;
  }

  return (
    <>
      <Button
        fullWidth
        variant="text"
        color="inherit"
        sx={{ justifyContent: "flex-start", pl: 2, pr: 3, borderRadius: 0 }}
        endIcon={
          <ArrowUpwardRounded
            sx={{ transition: "0.3s all" }}
            className={collapse ? "rotate-0" : "rotate-180"}
          />
        }
        onClick={() => setCollapse((old) => !old)}
      >
        <Box flex={1} display={"flex"}>
          <Typography variant="subtitle1">
            <FormattedMessage id="calendar_events" />
          </Typography>
        </Box>
      </Button>
      <Collapse in={collapse}>
        <Box className={"flex flex-col px-4 pb-4 -mt-1 gap-1"}>
          <Box component={"ul"} sx={{ mt: 0, pl: 2 }}>
            {leadDetail?.calendar_events.map((row, index) => {
              return <EventCard key={index} data={row} />;
            })}
          </Box>
        </Box>
      </Collapse>
      <Divider />
    </>
  );
};
export default EventsBox;
