import {
  Close,
  Delete,
  Edit,
  Pause,
  PlayArrow,
  Stop,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { uniqBy } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MyLink from "../../../../common/components/MyLink";
import {
  AGENT_ID_HEADER,
  KEYS_QUERY,
  KEY_FORM,
  some,
} from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import useParamsLead from "../../../../common/hook/useParamsLead";
import { setFormDataRedux } from "../../../../redux/formReducer";
import { addEmailForm } from "../../../../redux/leadReducer";
import { AppState } from "../../../../redux/store";
import { LeadSchemaFormContent } from "../../leads/body/LeadFormDialog";

interface Props {}

const LeadFormInfo: React.FunctionComponent<Props> = (props) => {
  const [isEditMode, setIsEditMode] = React.useState(false);
  const {
    intl,
    API_PATHS,
    dispatch,
    fetchThunk,
    enqueueSnackbar,
    getErrorMessage,
    confirmDialog,
    copyToClipboard,
  } = useGeneralHook();
  const navigate = useNavigate();

  const { promptConfirmation } = confirmDialog;

  const { leadId } = useParamsLead();
  const { refetch } = useQuery([leadId, KEYS_QUERY.agentLeadDetail], {
    enabled: false,
  });

  const userData = useSelector(
    (state: AppState) => state.auth.userData,
    shallowEqual
  );

  const agentData = useSelector(
    (state: AppState) => state.lead.agentDetail,
    shallowEqual
  );
  const leadDetail = useSelector(
    (state: AppState) => state.lead.leadDetail,
    shallowEqual
  );
  const isDeleted = leadDetail?.status.toLowerCase() === "deleted";
  const isActionPlanPaused =
    leadDetail?.automation_status?.toLowerCase() === "paused";

  const { refetch: refetchLead } = useQuery(
    [leadId, KEYS_QUERY.agentLeadDetail],
    { enabled: false }
  );

  const { data: actionPlans = [] } = useQuery(
    KEYS_QUERY.campaigns,
    async () => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.templates.getCampaigns({}),
        })
      );
      return json?.data?.data;
    }
  );

  const actionPlanName =
    actionPlans.find((v: some) => v.id === leadDetail?.automation_campaign_id)
      ?.name || "-";

  const onDeleteLead = async (value: some) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.agents_leads.detail(value.id),
          headers: { [AGENT_ID_HEADER]: value.agent_id },
          method: "DELETE",
        })
      );
      dispatch(
        setFormDataRedux({
          name: KEY_FORM.lead,
        })
      );
      refetch();
      enqueueSnackbar({
        message: <FormattedMessage id="deleteSuccess" />,
        variant: json.status,
      });
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  const onHardDeleteLead = async (value: some) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.agents_leads.bulkHardDelete,
          data: {
            agent_lead_ids: [value.id],
          },
          headers: { [AGENT_ID_HEADER]: value.agent_id },
          method: "DELETE",
        })
      );
      dispatch(
        setFormDataRedux({
          name: KEY_FORM.lead,
        })
      );
      navigate(`/agents/${agentData?.id}/contacts`);
      enqueueSnackbar({
        message: <FormattedMessage id="deleteSuccess" />,
        variant: json.status,
      });
    } catch (e: any) {
      enqueueSnackbar(getErrorMessage(e));
    }
  };

  const onDelete = async () => {
    const value = leadDetail || {};
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "deleteConfirm" },
        { name: value.name }
      ),
      onAccept: async () => {
        onDeleteLead(value);
      },
    });
  };

  const onHardDelete = async () => {
    const value = leadDetail || {};
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "hardDeleteConfirm" },
        { name: value.name }
      ),
      onAccept: async () => {
        onHardDeleteLead(value);
      },
    });
  };

  const onActionPlanResume = async () => {
    const value = leadDetail || {};
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "actionPlanBulkConfirm" },
        { action: "resume", name: value.name }
      ),
      onAccept: async () => {
        try {
          const json = await dispatch(
            fetchThunk({
              url: API_PATHS.automations.bulkResume,
              data: {
                agent_lead_ids: [value.id],
                campaign_id: value.automation_campaign_id,
              },
              headers: { [AGENT_ID_HEADER]: value.agent_id },
              method: "PUT",
            })
          );
          dispatch(
            setFormDataRedux({
              name: KEY_FORM.lead,
            })
          );
          refetch();
          enqueueSnackbar({
            message: <FormattedMessage id="updateSuccess" />,
            variant: json.status,
          });
        } catch (e: any) {
          enqueueSnackbar(getErrorMessage(e));
        }
      },
    });
  };

  const onActionPlanPause = async () => {
    const value = leadDetail || {};
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "actionPlanBulkConfirm" },
        { action: "pause", name: value.name }
      ),
      onAccept: async () => {
        try {
          const json = await dispatch(
            fetchThunk({
              url: API_PATHS.automations.bulkPause,
              data: {
                agent_lead_ids: [value.id],
                campaign_id: value.automation_campaign_id,
              },
              headers: { [AGENT_ID_HEADER]: value.agent_id },
              method: "PUT",
            })
          );
          dispatch(
            setFormDataRedux({
              name: KEY_FORM.lead,
            })
          );
          refetch();
          enqueueSnackbar({
            message: <FormattedMessage id="updateSuccess" />,
            variant: json.status,
          });
        } catch (e: any) {
          enqueueSnackbar(getErrorMessage(e));
        }
      },
    });
  };

  const onActionPlanStop = async () => {
    const value = leadDetail || {};
    await promptConfirmation({
      title: intl.formatMessage({ id: "confirm" }),
      content: intl.formatMessage(
        { id: "actionPlanBulkConfirm" },
        { action: "stop", name: value.name }
      ),
      onAccept: async () => {
        try {
          const json = await dispatch(
            fetchThunk({
              url: API_PATHS.automations.bulkStop,
              data: {
                agent_lead_ids: [value.id],
                campaign_id: value.automation_campaign_id,
              },
              headers: { [AGENT_ID_HEADER]: value.agent_id },
              method: "PUT",
            })
          );
          dispatch(
            setFormDataRedux({
              name: KEY_FORM.lead,
            })
          );
          refetch();
          enqueueSnackbar({
            message: <FormattedMessage id="updateSuccess" />,
            variant: json.status,
          });
        } catch (e: any) {
          enqueueSnackbar(getErrorMessage(e));
        }
      },
    });
  };

  const onUpdateLead = async (
    value: some,
    allow_duplicate?: boolean,
    allow_stage_update?: boolean
  ) => {
    try {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.agents_leads.detail(value.id),
          method: "put",
          headers: { [AGENT_ID_HEADER]: value.agent_id },
          data: {
            ...value,
            allow_duplicate: allow_duplicate || false,
            allow_stage_update: allow_stage_update || false,
            pause_active_automations: true,
          },
        })
      );
      refetchLead();
      setIsEditMode(false);
      enqueueSnackbar({
        message: (
          <FormattedMessage id={value.id ? "updateSuccess" : "createSuccess"} />
        ),
        variant: json.status,
      });
    } catch (e: any) {
      if (e.response.data?.errors[0]?.code === 409104) {
        await promptConfirmation({
          title: "confirm",
          content: e.response.data?.errors[0]?.message,
          onAccept: () => onUpdateLead(value, true),
        });
      } else if (e.response.data?.errors?.[0]?.code === 409107) {
        await promptConfirmation({
          title: intl.formatMessage({ id: "confirm" }),
          cancelId: "no.409107",
          okId: "yes.409107",
          content: e.response.data?.errors?.[0]?.message,
          onAccept: () => onUpdateLead(value, allow_duplicate, true),
          onCancel: () => {
            dispatch(
              setFormDataRedux({
                name: KEY_FORM.event,
                data: {
                  summary: `${value?.agent_name} x ${
                    value?.name || value?.email || "-"
                  }`,
                  start:
                    dayjs().hour() < 9
                      ? dayjs().startOf("day").add(10, "hours")
                      : dayjs().add(1, "day").startOf("day").add(10, "hours"),
                  end:
                    dayjs().hour() < 9
                      ? dayjs().startOf("day").add(11, "hours")
                      : dayjs().add(1, "day").startOf("day").add(11, "hours"),
                  reminders: {
                    useDefault: false,
                    overrides: [{ method: "popup", value: 1, type: "hours" }],
                  },
                  attendees: uniqBy(
                    [
                      {
                        id: value?.id,
                        email: value?.email,
                      },
                      {
                        email: userData?.email,
                      },
                      {
                        email: value?.agent_email,
                      },
                    ].filter((v) => v.email),
                    "email"
                  ),
                  agent_id: leadDetail?.agent_id,
                  agent_email: leadDetail?.agent_email,
                },
                callback: () => {
                  refetch();
                  setIsEditMode(false);
                },
              })
            );
          },
        });
      } else {
        enqueueSnackbar(getErrorMessage(e));
      }
    }
  };

  if (isEditMode) {
    return (
      <Box className={"flex flex-col p-4"}>
        <Box sx={{ display: "flex", mb: 1 }}>
          <Typography variant="subtitle1" flex={1}>
            <FormattedMessage id="update_lead" />
          </Typography>
          <Tooltip title={<FormattedMessage id="cancel" />}>
            <IconButton
              sx={{
                border: 1,
                borderColor: "divider",
              }}
              onClick={() => setIsEditMode(false)}
              size="small"
            >
              <Close fontSize={"inherit"} />
            </IconButton>
          </Tooltip>
        </Box>

        <LeadSchemaFormContent
          formData={leadDetail}
          onSubmit={onUpdateLead}
          onCancel={() => setIsEditMode(false)}
        />
      </Box>
    );
  }

  const CONTENT = [
    {
      title: "id",
      content: isDeleted ? `${leadDetail?.id} (deleted)` : leadDetail?.id,
    },
    {
      title: "email",
      color: "primary",
      content: leadDetail?.email ? (
        <Typography
          component={"span"}
          color={"inherit"}
          noWrap
          onClick={() => {
            const agentBusinessEmail = `${agentData?.name} <${agentData?.business_email}>`;
            const agentPersonalEmail = agentData?.personal_email;
            const leadEmail = `${leadDetail?.name} <${leadDetail?.email}>`;

            dispatch(
              addEmailForm({
                id: new Date().valueOf(),
                from: agentBusinessEmail,
                reply_to: agentBusinessEmail,
                to: leadEmail,
                cc: agentPersonalEmail,
              })
            );
          }}
        >
          {leadDetail?.email}
        </Typography>
      ) : (
        "-"
      ),
    },
    {
      title: "mobile_number",
      content: (
        <MyLink href={`tel:${leadDetail?.mobile_number}`}>
          {leadDetail?.mobile_number}
        </MyLink>
      ),
    },
    {
      title: "stage",
      content: leadDetail?.stage_name,
    },

    {
      title: "automation",
      content: actionPlanName,
      hasTopSpacing: true,
    },
    {
      title: "automation_template_name",
      content: leadDetail?.automation_template_name || "-",
    },
    {
      title: "automation_status",
      content: leadDetail?.automation_status || "-",
    },
    {
      title: "automation_scheduled_at",
      content: leadDetail?.automation_scheduled_at
        ? dayjs(leadDetail?.automation_scheduled_at).fromNow()
        : "-",
    },

    {
      title: "source",
      content: leadDetail?.source,
      hasTopSpacing: true,
    },
    {
      title: "address",
      content: leadDetail?.address,
    },
    {
      title: "notes",
      content: leadDetail?.notes,
    },
    {
      title: "tags",
      content:
        leadDetail?.tags?.length > 0
          ? leadDetail?.tags?.map((v) => v.name).join(", ")
          : "-",
    },

    {
      title: "created_at",
      content: leadDetail?.created_at
        ? dayjs(leadDetail?.created_at).format("LL")
        : "-",
      hasTopSpacing: true,
    },
    {
      title: "updated_at",
      content: leadDetail?.updated_at
        ? dayjs(leadDetail?.updated_at).fromNow()
        : "-",
    },
    {
      title: "last_activity",
      content: leadDetail?.last_activity
        ? dayjs(leadDetail?.last_activity).fromNow()
        : "-",
    },
    {
      title: "last_whatsapp",
      content: leadDetail?.last_whatsapp
        ? dayjs(leadDetail?.last_whatsapp).fromNow()
        : "-",
    },
    {
      title: "last_call",
      content: leadDetail?.last_call
        ? dayjs(leadDetail?.last_call).fromNow()
        : "-",
    },
    {
      title: "last_email",
      content: leadDetail?.last_email
        ? dayjs(leadDetail?.last_email).fromNow()
        : "-",
    },
  ];

  return (
    <Box className={"flex flex-col p-4 gap-0.5 relative"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {leadDetail?.automation_campaign_id && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Automation</Typography>
            {isActionPlanPaused ? (
              <Tooltip title={<FormattedMessage id={"resume"} />}>
                <IconButton onClick={onActionPlanResume} size="small">
                  <PlayArrow fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={<FormattedMessage id="pause" />}>
                <IconButton onClick={onActionPlanPause} size="small">
                  <Pause fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={<FormattedMessage id="stop" />}>
              <IconButton onClick={onActionPlanStop} size="small">
                <Stop fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>Action</Typography>
          <Tooltip title={<FormattedMessage id="delete" />}>
            <IconButton
              onClick={isDeleted ? onHardDelete : onDelete}
              size="small"
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title={<FormattedMessage id="update" />}>
            <IconButton onClick={() => setIsEditMode(true)} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {CONTENT.map((row, index) => {
        return (
          <Tooltip
            key={index}
            title={
              <>
                <FormattedMessage id={row.title} />
                :&nbsp;
                {row.content || "-"}
              </>
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                paddingTop: row.hasTopSpacing ? 1 : 0,
              }}
              onClick={() => copyToClipboard(row.content)}
            >
              <Typography variant="subtitle1" flexShrink={0}>
                <FormattedMessage id={row.title} />
                :&nbsp;
              </Typography>
              <Typography
                noWrap
                color={row.color}
                component={"div"}
                display={"flex"}
                alignItems={"center"}
              >
                {row.content || "-"}
              </Typography>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};
export default LeadFormInfo;
