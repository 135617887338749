import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { useShallowEqualSelector } from "../../common/hook/useShallowEqualSelector";
import LanguageSelect from "../../intl/component/LanguageSelect";
import { logoutSSO } from "../../redux/authReducer";
import { AppState } from "../../redux/store";
import ThemeSwitch from "./ThemeSwitch";
interface Props {
  sx?: BoxProps["sx"];
}
const ProfileBox = (props: Props) => {
  const { sx } = props;
  const { dispatch, isMobile } = useGeneralHook();
  const { userData } = useShallowEqualSelector((state: AppState) => state.auth);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "userInfo-popover" : undefined;
  const logout = () => {
    dispatch(logoutSSO());
  };

  return (
    <>
      <Button
        variant="text"
        className="items-center shrink-0 flex"
        sx={{
          gap: 0.5,
          display: "flex",
          justifyContent: "flex-start",
          textAlign: "start",
          ...sx,
        }}
        style={{ gap: 8 }}
        onClick={handleClick}
      >
        <Avatar
          src={userData?.photo_url}
          sx={{ minHeight: 32, minWidth: 32 }}
        />
        <Box>
          <Typography variant="subtitle1">{userData?.name}</Typography>
          <Typography variant="body2">{userData?.email}</Typography>
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          isMobile
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : { vertical: "bottom", horizontal: "center" }
        }
        transformOrigin={
          isMobile
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : undefined
        }
        className="mb-4 sm:mt-2"
        PaperProps={{
          variant: "outlined",
          elevation: 0,
          sx: {
            width: 260,
            p: 1,
            gap: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box className="flex gap-2 justify-around">
          <LanguageSelect />
          <ThemeSwitch />
        </Box>
        <Button onClick={logout} fullWidth startIcon={<Logout />}>
          <FormattedMessage id="logout" />
        </Button>
      </Popover>
    </>
  );
};
export default ProfileBox;
