import { Avatar, Button, IconButton, Paper, Typography } from "@mui/material";
import * as React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { development } from "../../api/gomedia";
import PopperWrapper from "../../common/components/PopperWrapper";
import { some } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { changeLocale } from "../../redux/intlReducer";
import { AppState } from "../../redux/store";
import iconFlagEn from "../../svg/flag_en.svg";
import iconFlagVi from "../../svg/flag_vi.svg";

const DATA = [
  { src: iconFlagVi, value: "vi", label: "Tiếng Việt" },
  { src: iconFlagEn, value: "en", label: "English" },
];

interface Props {}
const LanguageSelect: React.FC<Props> = () => {
  const { dispatch } = useGeneralHook();
  const locale = useSelector(
    (state: AppState) => state.intl.locale,
    shallowEqual
  );
  const [flag, setFlag] = React.useState<some>(
    DATA.find((one) => one.value === locale) || DATA[0]
  );
  if (!development) {
    return null;
  }
  return (
    <PopperWrapper
      popperProps={{
        style: { zIndex: 100 },
        placement: "bottom",
      }}
      content={
        <IconButton
          style={{
            padding: 0,
            overflow: "hidden",
          }}
          color="inherit"
        >
          <Avatar
            src={flag.src}
            alt=""
            style={{
              width: 32,
              height: 32,
              borderRadius: 32,
              objectFit: "cover",
            }}
          />
        </IconButton>
      }
    >
      <Paper
        style={{
          overflow: "hidden",
          padding: "8px 12px",
          marginTop: 16,
        }}
        variant="outlined"
      >
        {DATA.map((v: some, index: number) => {
          return (
            <Button
              variant="text"
              sx={{
                display: "flex",
                alignItems: "flex-right",
                justifyContent: "flex-start",
                padding: 1,
                borderRadius: 0.5,
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              fullWidth
              key={index}
              onClick={() => {
                setFlag(v);
                dispatch(changeLocale(v.value));
              }}
            >
              <img
                src={v.src}
                alt=""
                style={{ width: "38px", height: "25px", objectFit: "cover" }}
              />
              &nbsp;&nbsp;&nbsp;
              <Typography variant="body2">{v.label}</Typography>
            </Button>
          );
        })}
      </Paper>
    </PopperWrapper>
  );
};

export default LanguageSelect;
