import { CloseRounded } from "@mui/icons-material";
import { IconButton, Slide, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/vi";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router";
import "./App.css";
import { development } from "./api/gomedia";
import LoginPage from "./auth/page/LoginPage";
import AppointmentEventFormDialog from "./common/components/AppointmentEventFormDialog";
import ConfirmDialogProvider from "./common/components/ConfirmDialogProvider";
import FetchErrorDialog from "./common/components/FetchErrorDialog";
import LoadingIcon from "./common/components/LoadingIcon";
import ProtectedRoute from "./common/components/ProtectedRoute";
import RedirectRoute from "./common/components/RedirectRoute";
import WarningDialogProvider from "./common/components/WarningDialogProvider";
import { localeObject } from "./common/constants";
import useParamsLead from "./common/hook/useParamsLead";
import { getErrorMessage } from "./common/utils";
import { MUITheme } from "./config/setupTheme";
import { ROUTES_PATH } from "./layout/constants";
import AgentDetailLayout from "./layout/main/AgentDetailLayout";
import ProjectLayout from "./layout/main/ProjectLayout";
import PopperSendEmailBox from "./modules/opportunities/lead-detail/communications/email-tab/PopperSendEmailBox";
import { getUserInfo } from "./redux/authReducer";
import { AppState } from "./redux/store";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale("en", localeObject);
dayjs.updateLocale("vi", localeObject);

export const localeMap: any = {
  vi: "vi",
  en: "en",
};

function App() {
  const dispatch: any = useDispatch();
  const notistackRef = React.useRef<any>(null);
  const mode = useSelector((state: AppState) => state.cache.mode, shallowEqual);
  const { agentId } = useParamsLead();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        retry: 0,
        queryKey: [agentId],
        onError: (e: any) => {
          if (e?.response?.data?.errors?.length > 0)
            notistackRef.current.enqueueSnackbar(getErrorMessage(e));
          else
            notistackRef.current.enqueueSnackbar({
              message: e.message,
              variant: "error",
            });
        },
      },
      mutations: {
        onError: (e: any) => {
          if (e?.response?.data?.errors?.length > 0)
            notistackRef.current.enqueueSnackbar(getErrorMessage(e));
          else
            notistackRef.current.enqueueSnackbar({
              message: e.message,
              variant: "error",
            });
        },
      },
    },
  });

  const locale = useSelector(
    (state: AppState) => state.intl.locale,
    shallowEqual
  );

  const onClickDismiss = (key: any) => () => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  const routes = useRoutes([
    {
      path: ROUTES_PATH.login,
      element: (
        <RedirectRoute>
          <LoginPage />
        </RedirectRoute>
      ),
    },
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <ProjectLayout />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES_PATH.agents.detail,
      element: (
        <ProtectedRoute>
          <AgentDetailLayout />
        </ProtectedRoute>
      ),
    },
  ]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MUITheme(mode)}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={localeMap[locale]}
        >
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            preventDuplicate
            TransitionComponent={Slide}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseRounded style={{ color: "white" }} />
              </IconButton>
            )}
            autoHideDuration={3000}
          >
            <ConfirmDialogProvider>
              <WarningDialogProvider>
                <React.Suspense fallback={<LoadingIcon />}>
                  {routes}
                  <PopperSendEmailBox />
                  <AppointmentEventFormDialog />
                </React.Suspense>
                <FetchErrorDialog />
              </WarningDialogProvider>
            </ConfirmDialogProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
      {development && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;
