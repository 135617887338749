import { Box, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import SchemaForm from "../../../../common/SchemaForm";
import { validatePhoneNumber } from "../../../../common/SchemaForm/utils";
import DialogCustom from "../../../../common/components/DialogCustom";
import TagsSelectBox from "../../../../common/components/TagsSelectBox";
import { KEYS_QUERY, KEY_FORM, some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { titleCase } from "../../../../common/utils";
import { EMAIL_REGEX } from "../../../../config/regex";
import { setFormDataRedux } from "../../../../redux/formReducer";
import { AppState } from "../../../../redux/store";
interface Props {
  onSubmit: (formVal: some) => void;
}
interface ContentProps extends Props {
  formData?: some;
  onCancel?: () => void;
}

export const LeadSchemaFormContent: React.FunctionComponent<ContentProps> = (
  props
) => {
  const { onSubmit, formData, onCancel } = props;

  return (
    <SchemaForm
      onSubmit={(val) => onSubmit(val)}
      onCancel={onCancel}
      formData={
        formData && {
          ...formData,
          stage_id: formData.stage_id && {
            id: formData.stage_id,
            name: formData.stage_name,
          },
        }
      }
      getDirtyData
      schema={{
        fields: ({ formProps: { intl, API_PATHS }, valuesField }) => {
          const agentId = valuesField?.agent_id || valuesField.agent?.id;
          const ruleTel =
            !!valuesField?.id && valuesField.source === "Inbound Email"
              ? false
              : true;
          const ruleEmail =
            !!valuesField?.id && valuesField.source === "WhatsApp UIC"
              ? false
              : true;
          return {
            agent: {
              mode: "auto-complete",
              hidden: !!valuesField?.agent_id,
              label: intl.formatMessage({ id: "agent" }),
              placeholder: intl.formatMessage({ id: "select" }),
              loadOptions: {
                url: (q) => API_PATHS.agents.getList({ q }),
                mapped: (json) =>
                  json?.data.data?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  })),
              },
              getOptionLabel: (a: some) => `${a?.name}-${a?.id}`,
              rules: {
                required: true,
              },
            },
            name: {
              mode: "text-field",
              label: intl.formatMessage({ id: "name" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              autoFocus: !!!valuesField?.id,
              mapperValue: (e) => titleCase(e.target.value),
            },

            email: {
              mode: "text-field",
              label: intl.formatMessage({ id: "email" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              rules: ruleEmail
                ? {
                    pattern: {
                      value: EMAIL_REGEX,
                      message: intl.formatMessage({ id: "invalid_email" }),
                    },
                  }
                : undefined,
              readOnly: !!valuesField?.id && !!formData?.email,
              mapperValue: (e) => e.target.value?.toLowerCase(),
            },
            mobile_number: {
              mode: "phone-field",
              label: intl.formatMessage({ id: "mobile_number" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              rules: ruleTel
                ? {
                    required: ruleTel,
                    validate: (value) => {
                      const rs = validatePhoneNumber(value, intl);
                      return rs;
                    },
                  }
                : undefined,
              readOnly: !!valuesField?.id && !!formData?.mobile_number,
            },
            stage_id: {
              mode: "auto-complete",
              label: intl.formatMessage({ id: "stage" }),
              placeholder: intl.formatMessage({ id: "select" }),
              loadOptions: {
                url: (q) => API_PATHS.stages.getList({ q }),
                mapped: (json) => json?.data.data,
              },
              rules: {
                required: true,
              },
              defaultValue: { id: 1, name: "Potential" },
              disableClearable: true,
            },

            automation_campaign_id: {
              mode: "select",
              label: intl.formatMessage({ id: "automation" }),
              placeholder: intl.formatMessage({ id: "select" }),
              loadOptions: {
                url: API_PATHS.templates.getCampaigns({}),
                mapped: (json) =>
                  json?.data.data?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  })),
              },
            },
            source: {
              mode: "text-field",
              label: intl.formatMessage({ id: "source" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              defaultValue: window.location.href,
            },
            address: {
              mode: "text-field",
              label: intl.formatMessage({ id: "address" }),
              placeholder: intl.formatMessage({ id: "enter" }),
            },
            notes: {
              mode: "text-field",
              multiline: true,
              rows: 3,
              label: intl.formatMessage({ id: "notes" }),
              placeholder: intl.formatMessage({ id: "enter" }),
            },
            tags: {
              mode: "raw",
              render: (props) => {
                const {
                  field: { onChange, value },
                  fieldState: { invalid },
                } = props;
                return (
                  <TagsSelectBox
                    label={intl.formatMessage({ id: "tags" })}
                    placeholder={intl.formatMessage({ id: "select" })}
                    onChange={(params) => {
                      onChange(params);
                    }}
                    value={value}
                    error={invalid}
                    agentId={agentId}
                  />
                );
              },
            },
          };
        },
        changeDataBeforeSubmit: (values, dirtyFields) => {
          return {
            ...dirtyFields,
            tag_ids: values?.tags?.map((v) => v.id),
            agent: undefined,
            agent_id: values?.agent?.id || values?.agent_id,
            stage_id: values.stage_id?.id,
            tags: undefined,
            agent_name: values.agent_name,
            agent_email: values.agent_email,
          };
        },
      }}
    />
  );
};

const LeadFormDialog: React.FunctionComponent<Props> = (props) => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();
  const formData = useSelector(
    (state: AppState) => state.form.formData[KEY_FORM.lead]
  );

  const { data } = useQuery(
    [formData?.id, KEYS_QUERY.leadDetail],
    async () => {
      const json = await dispatch(
        fetchThunk({ url: API_PATHS.agents_leads.detail(formData?.id) })
      );
      return json.data.data;
    },
    { enabled: !!formData?.id }
  );
  const dataTmp = formData?.id ? data : formData;

  return (
    <DialogCustom
      open={!!dataTmp}
      fullWidth
      maxWidth="xs"
      onClose={(value, reason) => {
        if (reason === "button" || reason === "escapeKeyDown") {
          dispatch(setFormDataRedux({ name: KEY_FORM.lead }));
        }
      }}
      title={
        <Typography variant="h5" color={"primary"}>
          {dataTmp?.id ? dataTmp?.name : <FormattedMessage id="new" />}
        </Typography>
      }
    >
      <Box p={2} pt={0}>
        <LeadSchemaFormContent
          formData={dataTmp}
          onSubmit={props.onSubmit}
          onCancel={() => dispatch(setFormDataRedux({ name: KEY_FORM.lead }))}
        />
      </Box>
    </DialogCustom>
  );
};
export default LeadFormDialog;
