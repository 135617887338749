export const ROUTES_PATH = {
  login: "/login",
  leads: {
    index: "leads",
    detail: ":leadId/*",
    communications: {
      index: "communications/*",
      calls: "calls",
      whatsapp: "whatsapp",
      emails: "emails",
    },
    activities: "activities",
  },
  activities: {
    index: "activities",
  },
  inbox: {
    index: "inbox",
  },
  opportunities: {
    index: "opportunities",
    leads: {
      index: "leads",
      detail: ":leadId/*",
    },
  },
  templates: {
    index: "templates/*",
    whatsapp: "whatsapp",
    emails: "emails",
    new: "new",
    customized: "customized/:id",
    detail: ":id",
  },
  reports: {
    path: "reports",
    index: "reports/*",
    whatsapp: "whatsapp",
    breakdown: "breakdown",
    emails: "emails",
    calls: "calls",
    leads: "leads",
  },
  contacts: {
    index: "contacts",
    detail: ":leadId/*",
  },
  facebook: {
    index: "fb-ads-manager",
    createCampaign: "create-campaign",
  },
  agents: {
    index: "agents",
    profile: "profile",
    detail: "agents/:agentId/*",
  },
  blast: {
    index: "blast/*",
    secondary: "blast",
    emails: "emails",
    whatsapp: "whatsapp",
  },
};
