import {
  BarChart,
  Facebook,
  FlashOnRounded,
  Group,
  LibraryBooks,
  LockOpen,
  Paid,
  Person,
  Send,
  ViewColumn,
} from "@mui/icons-material";
import React from "react";
import { Navigate } from "react-router";
import { IRouteObject } from "../common/utils";
import LeadAgentDetailPageWrapper from "../modules/lead-detail/LeadAgentDetailPageWrapper";
import { ROUTES_PATH } from "./constants";

const AgentDetailPage = React.lazy(
  () => import("../modules/agents/AgentDetailPage")
);

const WhatsappMessageTab = React.lazy(
  () =>
    import(
      "../modules/opportunities/lead-detail/communications/whatsapp-message/WhatsappMessageTab"
    )
);
const CallTab = React.lazy(
  () =>
    import(
      "../modules/opportunities/lead-detail/communications/call-tab/CallTab"
    )
);
const EmailTab = React.lazy(
  () =>
    import(
      "../modules/opportunities/lead-detail/communications/email-tab/EmailTab"
    )
);
const ActivitiesPage = React.lazy(
  () => import("../modules/activities/ActivitiesPage")
);
const AgentsPage = React.lazy(() => import("../modules/agents/AgentsPage"));
const BlastPage = React.lazy(() => import("../modules/blast/BlastPage"));
const BlastEmailsHistory = React.lazy(
  () => import("../modules/blast/emails/BlastEmailsHistory")
);
const BlastWhatsappHistory = React.lazy(
  () => import("../modules/blast/whatsapp/BlastWhatsappHistory")
);
const ContactsPage = React.lazy(
  () => import("../modules/contact/ContactsPage")
);
const FacebookAdsManagerPage = React.lazy(
  () => import("../modules/facebook_ads/FacebookAdsManagerPage")
);
const CreateAdsCampaign = React.lazy(
  () => import("../modules/facebook_ads/campaigns/CreateAdsCampaign")
);
const InboxPage = React.lazy(() => import("../modules/inbox/InboxPage"));

const LeadPage = React.lazy(() => import("../modules/lead/LeadPage"));
const LeadDetailPageWrapper = React.lazy(
  () => import("../modules/opportunities/lead-detail/LeadDetailPageWrapper")
);
const ActivitiesTab = React.lazy(
  () => import("../modules/opportunities/lead-detail/activities/ActivitiesTab")
);
const CommunicationsTab = React.lazy(
  () =>
    import(
      "../modules/opportunities/lead-detail/communications/CommunicationsTab"
    )
);
const LeadsKanbanView = React.lazy(
  () => import("../modules/opportunities/leads/LeadsKanbanView")
);
const ReportPage = React.lazy(() => import("../modules/report/ReportPage"));
const CallsReportPage = React.lazy(
  () => import("../modules/report/calls/CallsReportPage")
);
const EmailsReportPage = React.lazy(
  () => import("../modules/report/emails/EmailsReportPage")
);
const BreakdownReportPage = React.lazy(
  () => import("../modules/report/breakdown/BreakdownReportPage")
);
const LeadsReportPage = React.lazy(
  () => import("../modules/report/leads/LeadsReportPage")
);
const WhatsAppsReportPage = React.lazy(
  () => import("../modules/report/whatsapp/WhatsAppsReportPage")
);
const TemplatePage = React.lazy(
  () => import("../modules/template/TemplatePage")
);
const TemplateEmailPage = React.lazy(
  () => import("../modules/template/email/TemplateEmailPage")
);
const TemplateMBGeneratorPage = React.lazy(
  () => import("../modules/template/messagebird/TemplateMBGeneratorPage")
);
const TemplateMBPage = React.lazy(
  () => import("../modules/template/messagebird/TemplateMBPage")
);

export const AGENT_ROUTES: IRouteObject[] = [
  {
    title: "contacts",
    path: ROUTES_PATH.contacts.index,
    icon: <Group />,
    children: [
      {
        element: <ContactsPage />,
        index: true,
      },
      {
        path: ROUTES_PATH.contacts.detail,
        title: "detail",
        element: <LeadDetailPageWrapper />,
        collapseWhenIn: true,
        hidden: true,
        children: [
          {
            path: ROUTES_PATH.leads.communications.index,
            title: "communications",
            element: <CommunicationsTab />,
            children: [
              {
                title: "whatsapp",
                path: ROUTES_PATH.leads.communications.whatsapp,
                element: <WhatsappMessageTab />,
              },
              {
                title: "call",
                path: ROUTES_PATH.leads.communications.calls,
                element: <CallTab />,
              },
              {
                title: "emails",
                path: ROUTES_PATH.leads.communications.emails,
                element: <EmailTab />,
              },
              {
                path: "*",
                element: (
                  <Navigate
                    replace
                    to={ROUTES_PATH.leads.communications.whatsapp}
                  />
                ),
                hidden: true,
              },
            ],
          },
          {
            path: ROUTES_PATH.leads.activities,
            title: "activities",
            element: <ActivitiesTab />,
          },
          {
            path: "*",
            element: (
              <Navigate replace to={ROUTES_PATH.leads.communications.index} />
            ),
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: "opportunities",
    path: ROUTES_PATH.opportunities.index,
    icon: <ViewColumn />,
    children: [
      {
        element: <LeadsKanbanView />,
        index: true,
      },
      {
        path: ROUTES_PATH.opportunities.leads.detail,
        portalId: "leadName",
        element: <LeadDetailPageWrapper />,
        hidden: true,
        children: [
          {
            path: ROUTES_PATH.leads.communications.index,
            element: <CommunicationsTab />,
            children: [
              {
                title: "whatsapp",
                path: ROUTES_PATH.leads.communications.whatsapp,
                element: <WhatsappMessageTab />,
              },
              {
                title: "call",
                path: ROUTES_PATH.leads.communications.calls,
                element: <CallTab />,
              },
              {
                title: "emails",
                path: ROUTES_PATH.leads.communications.emails,
                element: <EmailTab />,
              },
              {
                path: "*",
                element: (
                  <Navigate
                    replace
                    to={ROUTES_PATH.leads.communications.whatsapp}
                  />
                ),
                hidden: true,
              },
            ],
          },
          {
            path: ROUTES_PATH.leads.activities,
            element: <ActivitiesTab />,
          },
          {
            path: "*",
            element: (
              <Navigate replace to={ROUTES_PATH.leads.communications.index} />
            ),
            hidden: true,
          },
        ],
      },
      // {
      //   path: "*",
      //   element: <Navigate replace to={ROUTES_PATH.opportunities.index} />,
      //   hidden: true,
      // },
    ],
  },
  {
    title: "templates",
    path: ROUTES_PATH.templates.index,
    icon: <LibraryBooks />,
    element: <TemplatePage />,
    children: [
      {
        path: ROUTES_PATH.templates.whatsapp,
        title: "whatsapp",
        hidden: true,
        children: [
          {
            index: true,
            element: <TemplateMBPage />,
          },
          {
            path: ROUTES_PATH.templates.new,
            title: "new",
            element: <TemplateMBGeneratorPage mode={"create"} />,
          },
          {
            path: ROUTES_PATH.templates.customized,
            title: "customized",
            element: <TemplateMBGeneratorPage mode={"customized"} />,
          },
          {
            path: ROUTES_PATH.templates.detail,
            title: "detail",
            element: <TemplateMBGeneratorPage mode={"view"} />,
          },
        ],
      },
      {
        path: ROUTES_PATH.templates.emails,
        element: <TemplateEmailPage />,
        title: "emails",
        hidden: true,
      },
      {
        path: "*",
        element: <Navigate replace to={ROUTES_PATH.templates.whatsapp} />,
        hidden: true,
      },
    ],
  },
  {
    path: ROUTES_PATH.inbox.index,
    icon: <Send />,
    title: "inbox",
    children: [
      {
        element: <InboxPage />,
        index: true,
      },
    ],
  },
  {
    title: "reports",
    path: ROUTES_PATH.reports.index,
    element: <ReportPage />,
    icon: <BarChart />,
    children: [
      {
        path: ROUTES_PATH.reports.calls,
        element: <CallsReportPage />,
        title: "calls",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.emails,
        element: <EmailsReportPage />,
        title: "emails",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.leads,
        element: <LeadsReportPage />,
        title: "leads",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.whatsapp,
        element: <WhatsAppsReportPage />,
        title: "whatsapp",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.breakdown,
        element: <BreakdownReportPage />,
        title: "breakdown",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.reports.breakdown} />,
      },
    ],
  },
  {
    title: "blast",
    path: ROUTES_PATH.blast.index,
    icon: <FlashOnRounded />,
    element: <BlastPage />,
    children: [
      {
        path: ROUTES_PATH.blast.emails,
        element: <BlastEmailsHistory />,
        title: "emails",
        hidden: true,
      },
      {
        path: ROUTES_PATH.blast.whatsapp,
        element: <BlastWhatsappHistory />,
        title: "whatsapp",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.blast.whatsapp} />,
      },
    ],
  },
  {
    title: "profile",
    path: ROUTES_PATH.agents.profile,
    element: <AgentDetailPage />,
    icon: <Person />,
  },
  {
    path: "*",
    element: <Navigate replace to={ROUTES_PATH.contacts.index} />,
    hidden: true,
  },
];

export const MAIN_ROUTES: IRouteObject[] = [
  {
    title: "agents",
    path: ROUTES_PATH.agents.index,
    icon: <Paid />,
    // element: <AgentsPage />,
    children: [
      {
        index: true,
        element: <AgentsPage />,
      },
    ],
  },
  {
    path: ROUTES_PATH.leads.index,
    icon: <Group />,
    title: "leads",
    children: [
      {
        element: <LeadPage />,
        index: true,
      },
      {
        path: ROUTES_PATH.leads.detail,
        portalId: "leadName",
        element: <LeadAgentDetailPageWrapper />,
        collapseWhenIn: true,
        hidden: true,
        blockBreadcrumb: true,
        children: [
          {
            path: ROUTES_PATH.leads.communications.index,
            title: "communications",
            element: <CommunicationsTab />,
            children: [
              {
                title: "whatsapp",
                path: ROUTES_PATH.leads.communications.whatsapp,
                element: <WhatsappMessageTab />,
              },
              {
                title: "call",
                path: ROUTES_PATH.leads.communications.calls,
                element: <CallTab />,
              },
              {
                title: "emails",
                path: ROUTES_PATH.leads.communications.emails,
                element: <EmailTab />,
              },
              {
                path: "*",
                element: (
                  <Navigate
                    replace
                    to={ROUTES_PATH.leads.communications.whatsapp}
                  />
                ),
                hidden: true,
              },
            ],
          },
          {
            path: ROUTES_PATH.leads.activities,
            title: "activities",
            element: <ActivitiesTab />,
          },
          {
            path: "*",
            element: (
              <Navigate replace to={ROUTES_PATH.leads.communications.index} />
            ),
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES_PATH.inbox.index,
    icon: <Send />,
    title: "inbox",
    children: [
      {
        element: <InboxPage />,
        index: true,
      },
    ],
  },
  {
    title: "reports",
    path: ROUTES_PATH.reports.index,
    icon: <BarChart />,
    element: <ReportPage />,
    children: [
      {
        path: ROUTES_PATH.reports.calls,
        element: <CallsReportPage />,
        title: "calls",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.emails,
        element: <EmailsReportPage />,
        title: "emails",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.leads,
        element: <LeadsReportPage />,
        title: "leads",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.whatsapp,
        element: <WhatsAppsReportPage />,
        title: "whatsapp",
        hidden: true,
      },
      {
        path: ROUTES_PATH.reports.breakdown,
        element: <BreakdownReportPage />,
        title: "breakdown",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.reports.breakdown} />,
      },
    ],
  },
  {
    title: "blast",
    path: ROUTES_PATH.blast.index,
    icon: <FlashOnRounded />,
    element: <BlastPage />,
    children: [
      {
        path: ROUTES_PATH.blast.emails,
        element: <BlastEmailsHistory />,
        title: "emails",
        hidden: true,
      },
      {
        path: ROUTES_PATH.blast.whatsapp,
        element: <BlastWhatsappHistory />,
        title: "whatsapp",
        hidden: true,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate replace to={ROUTES_PATH.blast.whatsapp} />,
      },
    ],
  },
  {
    title: "facebook_ads_manager",
    path: ROUTES_PATH.facebook.index,
    icon: <Facebook />,
    hidden: true,
    children: [
      {
        element: <FacebookAdsManagerPage />,
        index: true,
      },
      {
        path: ROUTES_PATH.facebook.createCampaign,
        title: "create_ads_campaign",
        element: <CreateAdsCampaign />,
        hidden: true,
      },
    ],
  },

  // keep this last
  {
    path: ROUTES_PATH.activities.index,
    icon: <LockOpen />,
    title: "activities",
    listRole: [["READ_ACTIVITIES"]],
    children: [
      {
        element: <ActivitiesPage />,
        index: true,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate replace to={ROUTES_PATH.agents.index} />,
    hidden: true,
  },
];
