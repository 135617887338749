import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import LeadAgentDetailPage from "./LeadAgentDetailPage";
import useGeneralHook from "../../common/hook/useGeneralHook";

interface Props {}

const LeadAgentDetailPageWrapper: React.FunctionComponent<Props> = (props) => {
  const { enqueueSnackbar, getErrorMessage } = useGeneralHook();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        retry: 0,
        onError: (e: any) => {
          if (e?.response?.data?.errors?.length > 0)
            enqueueSnackbar(getErrorMessage(e));
          else
            enqueueSnackbar({
              message: e.message,
              variant: "error",
            });
        },
        refetchInterval: 30000,
      },
      mutations: {
        onError: (e: any) => {
          if (e?.response?.data?.errors?.length > 0)
            enqueueSnackbar(getErrorMessage(e));
          else
            enqueueSnackbar({
              message: e.message || JSON.stringify(e),
              variant: "error",
            });
        },
      },
    },
  });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <LeadAgentDetailPage />
      </QueryClientProvider>
    </>
  );
};
export default LeadAgentDetailPageWrapper;
