import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { some } from "../../../../common/constants";
import { IconFacebook, IconGoogle } from "../../../../svg";

interface Props {
  index: number;
  data: some;
}
const SubmissionCard = (props: Props) => {
  const { index, data } = props;
  return (
    <Box
      sx={{
        borderTop: index === 0 ? 0 : 1,
        borderColor: "divider",
        marginBottom: 1,
      }}
    >
      <Typography variant="subtitle1" display="inline">
        Submitted:&nbsp;
      </Typography>
      <Typography display="inline">
        {data?.created_at ? dayjs(data?.created_at).fromNow() : "-"}
      </Typography>
      {data.source && (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography variant="subtitle1" display="inline">
            Source:&nbsp;
          </Typography>
          {data.source.toLowerCase() === "google" ? (
            <IconGoogle height={16} />
          ) : data.source.toLowerCase() === "facebook" ? (
            <IconFacebook height={20} />
          ) : null}
          <Typography display="inline">{data.source}</Typography>
        </Box>
      )}
      {data.secondary_source && (
        <Box>
          <Typography variant="subtitle1" display="inline">
            Secondary Source:&nbsp;
          </Typography>
          <Typography display="inline">{data.secondary_source}</Typography>
        </Box>
      )}
      {data.tertiary_source && (
        <Box>
          <Typography variant="subtitle1" display="inline">
            Tertiary Source:&nbsp;
          </Typography>
          <Typography display="inline">{data.tertiary_source}</Typography>
        </Box>
      )}
      {Object.entries(data?.qna)?.map((item: any, index) => {
        if (!item[1]) return null;
        return (
          <Box key={index}>
            <Typography variant="subtitle1" display="inline">
              {item[0]
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              :&nbsp;
            </Typography>
            <Typography display="inline">{item[1]}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
export default SubmissionCard;
