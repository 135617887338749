import { EmailOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import LoadingIcon from "../../common/components/LoadingIcon";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { loginSSO } from "../../redux/authReducer";
import { AppState } from "../../redux/store";
import { IconGoogle, LoginBG, LogoHorizontal } from "../../svg";

interface Props {}

const LoginPage = (props: Props) => {
  const { loading, validate } = useSelector(
    (state: AppState) => state.auth,
    shallowEqual
  );
  const { dispatch } = useGeneralHook();

  const login = async () => {
    dispatch(loginSSO());
  };
  if (validate) {
    return <LoadingIcon sx={{ height: "100vh" }} />;
  }
  return (
    <>
      <Box
        display="flex"
        height="100vh"
        width={"100vw"}
        bgcolor="background.paper"
      >
        <Box display="flex" flexDirection="column" height="100%" flex={1}>
          <Typography
            sx={(theme) => ({
              m: 6,
              height: 80,
              [theme.breakpoints.down("sm")]: {
                m: 4,
                mx: "auto",
                mt: 12,
                height: 100,
              },
            })}
          >
            <LogoHorizontal style={{ height: "inherit", width: "auto" }} />
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            height="100%"
            flex={1}
            sx={(theme) => ({
              p: 15,
              justifyContent: "center",
              [theme.breakpoints.down("sm")]: {
                px: 4,
                pb: 12,
                pt: 4,
                justifyContent: "flex-start",
              },
            })}
          >
            <Box width={"100%"} pb={15}>
              <Typography variant="h5" style={{ marginBottom: 10 }}>
                <FormattedMessage id="welcome_back" />
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginBottom: 28 }}
              >
                <FormattedMessage id="login_helper" />
              </Typography>
              <LoadingButton
                loading={loading}
                loadingPosition="center"
                startIcon={<IconGoogle />}
                size="large"
                sx={{ width: "100%", boxShadow: (theme) => theme.shadows[3] }}
                onClick={() => login()}
                variant="outlined"
              >
                <FormattedMessage id="sign_in_google" />
              </LoadingButton>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
            sx={(theme) => ({
              p: 7,
              [theme.breakpoints.down("sm")]: { p: 2 },
            })}
          >
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="gogo_intro" />
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component={"div"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <EmailOutlined />
              &nbsp; <FormattedMessage id="gogo_email_helper" />
            </Typography>
          </Box>
        </Box>
        <Box
          component={"img"}
          src={LoginBG}
          style={{
            flex: 1,
            height: "100vh",
            objectFit: "cover",
            overflow: "hidden",
          }}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: { display: "none" },
          })}
          alt="login_bg"
        />
      </Box>
    </>
  );
};
export default LoginPage;
