import {
  ArrowBackRounded,
  CalendarMonth,
  Email,
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
  Phone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Portal,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { uniqBy } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import MyLink from "../../../../common/components/MyLink";
import { KEYS_QUERY, KEY_FORM } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { setFormDataRedux } from "../../../../redux/formReducer";
import { addEmailForm } from "../../../../redux/leadReducer";
import { AppState } from "../../../../redux/store";
import { LEFT_BTN_ID } from "../../constant";
import AgentDataBox from "./AgentDataBox";
import EventsBox from "./EventsBox";
import LeadDataBox from "./LeadDataBox";
import LeadFormInfo from "./LeadFormInfo";

interface Props {}

const LeadInfoBox: React.FunctionComponent<Props> = (props) => {
  const { isMobile, theme, dispatch } = useGeneralHook();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(!isTablet);

  const [, setTick] = React.useState(false);
  const { leadId } = useParams<{ leadId: string }>();

  const { refetch } = useQuery([leadId, KEYS_QUERY.agentLeadDetail], {
    enabled: false,
  });

  const userData = useSelector(
    (state: AppState) => state.auth.userData,
    shallowEqual
  );

  const agentData = useSelector(
    (state: AppState) => state.lead.agentDetail,
    shallowEqual
  );
  const leadDetail = useSelector(
    (state: AppState) => state.lead.leadDetail,
    shallowEqual
  );

  const content = (
    <>
      <div className="flex gap-4 pt-4 pr-4 pl-4 items-start">
        <Button
          variant="text"
          onClick={() => {
            navigate(`/agents/${agentData?.id}/contacts`);
          }}
        >
          <ArrowBackRounded sx={{ fontSize: 24 }} />
        </Button>
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Typography variant="h4">
            {leadDetail ? (
              leadDetail?.name || "-"
            ) : (
              <Skeleton width={120} sx={{ mx: "auto" }} />
            )}
          </Typography>
          <Typography color={"textSecondary"}>
            {leadDetail?.last_activity
              ? dayjs(leadDetail?.last_activity).format("LL")
              : "-"}
          </Typography>
        </Box>

        <Button variant="text" onClick={() => setOpen((old) => !old)}>
          {open ? (
            <KeyboardDoubleArrowLeftOutlined />
          ) : (
            <KeyboardDoubleArrowRightOutlined />
          )}
        </Button>
      </div>
      {/* Lead info */}
      <div className="overflow-auto">
        <div className="flex flex-col px-8">
          <div className="flex justify-around gap-8 my-2 flex-1">
            <Tooltip title={leadDetail?.email}>
              <div
                className="text-center flex-1"
                onClick={() => {
                  const agentBusinessEmail = `${agentData?.name} <${agentData?.business_email}>`;
                  const agentPersonalEmail = agentData?.personal_email;
                  const leadEmail = `${leadDetail?.name} <${leadDetail?.email}>`;

                  dispatch(
                    addEmailForm({
                      id: new Date().valueOf(),
                      from: agentBusinessEmail,
                      reply_to: agentBusinessEmail,
                      to: leadEmail,
                      cc: agentPersonalEmail,
                    })
                  );
                }}
              >
                <IconButton sx={{ border: 1, borderColor: "divider" }}>
                  <Email />
                </IconButton>
                <Typography mt={0.5}>
                  <FormattedMessage id="email" />
                </Typography>
              </div>
            </Tooltip>
            <Tooltip title={leadDetail?.mobile_number}>
              <div className="text-center flex-1">
                <MyLink href={`tel:${leadDetail?.mobile_number}`}>
                  <IconButton sx={{ border: 1, borderColor: "divider" }}>
                    <Phone />
                  </IconButton>
                </MyLink>
                <Typography mt={0.5}>
                  <FormattedMessage id="call" />
                </Typography>
              </div>
            </Tooltip>
            <div className="text-center flex-1">
              <IconButton
                sx={{ border: 1, borderColor: "divider" }}
                onClick={() => {
                  dispatch(
                    setFormDataRedux({
                      name: KEY_FORM.event,
                      data: {
                        summary: `${agentData?.name} x ${
                          leadDetail?.name || leadDetail?.email || "-"
                        }`,
                        start:
                          dayjs().hour() < 9
                            ? dayjs().startOf("day").add(10, "hours")
                            : dayjs()
                                .add(1, "day")
                                .startOf("day")
                                .add(10, "hours"),
                        end:
                          dayjs().hour() < 9
                            ? dayjs().startOf("day").add(11, "hours")
                            : dayjs()
                                .add(1, "day")
                                .startOf("day")
                                .add(11, "hours"),
                        reminders: {
                          useDefault: false,
                          overrides: [
                            { method: "popup", value: 1, type: "hours" },
                          ],
                        },
                        attendees: uniqBy(
                          [
                            {
                              id: leadDetail?.id,
                              email: leadDetail?.email,
                            },
                            {
                              email: userData?.email,
                            },
                            {
                              email: agentData?.personal_email,
                            },
                          ].filter((v) => v.email),
                          "email"
                        ),
                        agent_id: leadDetail?.agent_id,
                        agent_email: leadDetail?.agent_email,
                      },
                      callback: () => {
                        refetch();
                        setOpen(false);
                      },
                    })
                  );
                }}
              >
                <CalendarMonth />
              </IconButton>
              <Typography mt={0.5}>
                <FormattedMessage id="appointment" />
              </Typography>
            </div>
          </div>
        </div>
        <Divider />
        <LeadFormInfo />
        <Divider />
        <AgentDataBox />
        <Divider />
        <EventsBox />
        <LeadDataBox />
      </div>
    </>
  );

  React.useEffect(() => {
    if (isTablet) {
      setOpen(false);
    }
  }, [isTablet]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (document.getElementById(LEFT_BTN_ID)) {
        setTick((old) => !old);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [isMobile, isTablet]);

  if (isMobile) {
    return (
      <>
        <Portal container={document.getElementById(LEFT_BTN_ID)}>
          <Button
            variant="text"
            onClick={() => setOpen((old) => !old)}
            onMouseEnter={(e) => {
              e.stopPropagation();
            }}
            sx={{ mx: 1 }}
          >
            <KeyboardDoubleArrowRightOutlined />
          </Button>
        </Portal>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          anchor="left"
          PaperProps={{ sx: { width: "85vw", borderRadius: 0 } }}
        >
          {content}
        </Drawer>
      </>
    );
  }

  return (
    <>
      {!open && (
        <Portal container={document.getElementById(LEFT_BTN_ID)}>
          <Button
            variant="text"
            onClick={() => setOpen((old) => !old)}
            onMouseEnter={(e) => {
              e.stopPropagation();
            }}
            sx={{ mx: 1 }}
          >
            <KeyboardDoubleArrowRightOutlined />
          </Button>
        </Portal>
      )}
      {open && (
        <Box
          sx={{
            width: 336,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {content}
        </Box>
      )}
    </>
  );
};
export default LeadInfoBox;
