import React, { FunctionComponent, useEffect } from "react";
import { useQuery } from "react-query";
import { Outlet } from "react-router";
import LoadingIcon from "../../common/components/LoadingIcon";
import NotFoundBox from "../../common/components/NotFoundBox";
import { KEYS_QUERY } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import useParamsLead from "../../common/hook/useParamsLead";
import { setBreadcrumb } from "../../redux/cacheReducer";
import { setAgentDetail, setLeadDetail } from "../../redux/leadReducer";
import LeadInfoBox from "../opportunities/lead-detail/LeadInfoBox";
import RightMenuBox from "../opportunities/lead-detail/RightMenuBox";
import LeadTab from "./LeadTab";

interface Props {}

const LeadAgentDetailPage: FunctionComponent<Props> = (props) => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();

  const { leadId } = useParamsLead();

  const { data, isLoading } = useQuery(
    [leadId, KEYS_QUERY.agentLeadDetail],
    async () => {
      if (leadId) {
        const json = await dispatch(
          fetchThunk({
            url: API_PATHS.agents_leads.detail(leadId),
          })
        );
        return json?.data?.data;
      }
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: "always",
      enabled: !!leadId,
    }
  );

  useQuery(
    [data?.agent_id, KEYS_QUERY.agentDetail],
    async () => {
      const json = await dispatch(
        fetchThunk({
          url: API_PATHS.agents.detail(data?.agent_id),
        })
      );
      return json.data.data;
    },
    {
      enabled: !!data?.agent_id,
      onSuccess: (data) => {
        dispatch(setAgentDetail(data));
      },
    }
  );

  useEffect(() => {
    dispatch(setLeadDetail(data));
    dispatch(
      setBreadcrumb({
        leadName: data ? `${data?.name || ""}-${data?.agent_name}` : undefined,
      })
    );
    return () => {
      dispatch(setLeadDetail(undefined));
      dispatch(setBreadcrumb({ leadName: undefined }));
      dispatch(setAgentDetail(undefined));
    };
  }, [dispatch, data]);

  if (!data && !isLoading) {
    return <NotFoundBox />;
  }

  return (
    <>
      <div className="flex flex-1 overflow-hidden relative">
        <LeadInfoBox />
        <div className="p-2 sm:p-4 gap-2 overflow-hidden flex-1 shrink-0 flex flex-col">
          <LeadTab />
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <React.Suspense fallback={<LoadingIcon />}>
              <Outlet />
            </React.Suspense>
          )}
        </div>
        <RightMenuBox />
      </div>
    </>
  );
};
export default LeadAgentDetailPage;
