import { ArrowUpwardRounded } from "@mui/icons-material";
import { Box, Button, Collapse, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { AppState } from "../../../../redux/store";

interface Props {}

const AgentDataBox: React.FunctionComponent<Props> = (props) => {
  const [collapse, setCollapse] = React.useState(false);
  const agentDetail = useSelector(
    (state: AppState) => state.lead.agentDetail,
    shallowEqual
  );
  const { copyToClipboard } = useGeneralHook();

  const CONTENT = [
    {
      title: "personal_email",
      content: agentDetail?.personal_email,
    },
    {
      title: "business_email",
      content: agentDetail?.business_email,
    },
    {
      title: "personal_mobile_number",
      content: agentDetail?.personal_mobile_number,
    },
    {
      title: "business_mobile_number",
      content: agentDetail?.business_mobile_number,
    },
    {
      title: "aircall_number",
      content: agentDetail?.aircall_number_id,
    },
    {
      title: "messagebird_channel_id",
      content: agentDetail?.messagebird_channel_id,
    },
    {
      title: "automations",
      content: agentDetail?.automations_enabled ? "Yes" : "No",
    },
    {
      title: "cea_number",
      content: agentDetail?.cea_number ? agentDetail?.cea_number : "-",
    },
    {
      title: "agency_name",
      content: agentDetail?.agency_name ? agentDetail?.agency_name : "-",
    },
  ];
  if (!agentDetail) {
    return null;
  }
  return (
    <Box className="flex flex-col">
      <Button
        fullWidth
        variant="text"
        color="inherit"
        sx={{ justifyContent: "flex-start", pl: 2, pr: 3, borderRadius: 0 }}
        endIcon={
          <ArrowUpwardRounded
            sx={{ transition: "0.1s all" }}
            className={collapse ? "rotate-0" : "rotate-180"}
          />
        }
        onClick={() => setCollapse((old) => !old)}
      >
        <Box flex={1} display={"flex"} alignItems={"center"}>
          <Typography variant="subtitle1" flexShrink={0}>
            <FormattedMessage id={"agent"} />
            :&nbsp;
          </Typography>
          <Typography noWrap>{agentDetail.name || "-"}</Typography>
        </Box>
      </Button>
      <Collapse in={collapse}>
        <Box className={"flex flex-col px-4 pb-2"}>
          {CONTENT.map((row, index) => {
            return (
              <Box
                key={index}
                className="flex items-center"
                onClick={() => copyToClipboard(row.content)}
                sx={{ cursor: "pointer" }}
              >
                <Typography variant="subtitle1" flexShrink={0}>
                  <FormattedMessage id={row.title} />
                  :&nbsp;
                </Typography>
                <Typography noWrap>{row.content || "-"}</Typography>
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};
export default AgentDataBox;
