import { Box, LinearProgress, alpha } from "@mui/material";
import * as React from "react";
import { useQuery } from "react-query";
import { shallowEqual, useSelector } from "react-redux";
import { useRoutes } from "react-router";
import LoadingIcon from "../../common/components/LoadingIcon";
import NotFoundBox from "../../common/components/NotFoundBox";
import { KEYS_QUERY } from "../../common/constants";
import useGeneralHook from "../../common/hook/useGeneralHook";
import useParamsLead from "../../common/hook/useParamsLead";
import { setAgentDetail } from "../../redux/leadReducer";
import { AppState } from "../../redux/store";
import { AGENT_ROUTES } from "../routes";
import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import { mappedRoutesPermission } from "./utils";

const ProcessLoading = React.memo(
  () => {
    const loading = useSelector(
      (state: AppState) => state.common.loading,
      shallowEqual
    );
    if (loading) {
      return (
        <LinearProgress
          sx={{ position: "fixed", top: 0, right: 0, left: 0, zIndex: 100 }}
        />
      );
    }
    return null;
  },
  () => true
);

interface Props {}

const AgentDetailLayout = (props: Props) => {
  const { API_PATHS, dispatch, fetchThunk } = useGeneralHook();
  const userData = useSelector((state: AppState) => state.auth.userData);
  const mappedRoutes = mappedRoutesPermission(
    userData?.permissions || [],
    AGENT_ROUTES
  ) as any;

  const element = useRoutes(mappedRoutes);

  const { agentId } = useParamsLead();

  const { data, isLoading } = useQuery(
    [agentId, KEYS_QUERY.agentDetail],
    async () => {
      const json = await dispatch(
        fetchThunk({ url: API_PATHS.agents.detail(agentId) })
      );
      return json.data.data;
    },
    {
      enabled: !!agentId,
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    dispatch(setAgentDetail(data));
    document.title = `GoAdmin${data?.name ? ` | ${data?.name}` : ""}`;
    return () => {
      dispatch(setAgentDetail(undefined));
    };
  }, [data, dispatch]);

  return (
    <Box display="flex" bgcolor={"background.default"}>
      <DefaultAside
        listRouter={mappedRoutes}
        prefix="/agents/:agentId/"
        sxContainer={{
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.15),
        }}
      />
      <Box className="relative flex-1 h-screen">
        <Box className="absolute top-0 h-screen w-screen sm:w-full sm:inset-0  flex-1 flex flex-col overflow-hidden">
          <DefaultHeader hidedBreadcrumb />
          <ProcessLoading />
          {isLoading ? (
            <LoadingIcon />
          ) : !data ? (
            <NotFoundBox />
          ) : (
            <React.Suspense fallback={<LoadingIcon />}>
              {element}
            </React.Suspense>
          )}
        </Box>
      </Box>
      <Box id="aside-right" />
    </Box>
  );
};

export default AgentDetailLayout;
