import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Collapse, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../common/components/LinkPM";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { IRouteObject } from "../../common/utils";
import { useMatch } from "react-router";
interface Props {
  data: IRouteObject;
  pathname: string;
  open?: boolean;
  prefix?: string;
}

const DefaultAsideItems: React.FC<Props> = (props: Props) => {
  const { data, pathname, open, prefix = "" } = props;
  const { isMobile } = useGeneralHook();
  const [openList, setOpen] = React.useState(false);
  const ASIDE_MIN_WIDTH = isMobile ? 48 : 64;

  const match = useMatch(
    prefix +
      (data.index
        ? "/" + (data.path?.replace("/*", "") || "")
        : (data.path?.replace("/*", "") || "") + "/*")
  );

  const checkIsActive = !!match;

  useEffect(() => {
    setOpen(checkIsActive);
  }, [checkIsActive, pathname]);

  if (data.hidden || !data.path) {
    return null;
  }
  if (data.children && data.children?.length > 1) {
    return (
      <Box
        bgcolor={checkIsActive ? "primary.dark" : undefined}
        style={{
          borderRadius: 4,
          width: open ? "unset" : ASIDE_MIN_WIDTH - 18,
        }}
      >
        <Tooltip
          title={
            !open && (
              <Typography color="inherit" noWrap>
                {data.title && <FormattedMessage id={data.title} />}
              </Typography>
            )
          }
          placement="right"
        >
          <Button
            variant={"text"}
            style={{
              textAlign: "start",
              padding: isMobile ? "8px" : "8px 12px",
              justifyContent: "flex-start",
              minWidth: "-webkit-fill-available",
            }}
            sx={{
              color: checkIsActive ? "common.white" : "text.primary",
            }}
            fullWidth
            disableElevation
            endIcon={
              open && (
                <KeyboardArrowDownIcon
                  style={{
                    transform: openList ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "all 200ms",
                    fontSize: 24,
                  }}
                />
              )
            }
            onClick={() => setOpen(!openList)}
          >
            <Box
              color={checkIsActive ? "white" : "primary.main"}
              height={24}
              marginRight={2}
            >
              {data.icon}
            </Box>
            <Typography
              variant={checkIsActive ? "subtitle2" : "body2"}
              color="inherit"
              noWrap
              style={{ flex: 1, textAlign: "start" }}
            >
              {data.title && <FormattedMessage id={data.title} />}
            </Typography>
          </Button>
        </Tooltip>

        <Collapse in={openList && open} unmountOnExit>
          {data.children?.map((item) => {
            if (!item.path || item.hidden) {
              return null;
            }
            const checkIsActiveSub = item.path && pathname.includes(item.path);

            return (
              <LinkPM
                key={item.path?.replace("/*", "")}
                to={{
                  pathname: item.path,
                }}
                style={{ display: "flex", flex: 1 }}
              >
                <Button
                  fullWidth
                  variant="text"
                  sx={{
                    color: checkIsActiveSub
                      ? "common.white"
                      : !checkIsActive
                      ? "text.secondary"
                      : "info.light",
                  }}
                  style={{
                    padding: "8px 12px",
                    display: "flex",
                    justifyContent: "flex-start",
                    minWidth: "-webkit-fill-available",
                  }}
                >
                  <Box height={24} marginRight={2}>
                    {item.icon || (
                      <FiberManualRecordIcon
                        sx={{
                          color: checkIsActiveSub
                            ? "common.white"
                            : "info.light",
                          transform: checkIsActiveSub
                            ? "scale(.6)"
                            : "scale(.3)",
                          fontSize: 24,
                          transition:
                            "all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        }}
                      />
                    )}
                  </Box>
                  <Typography
                    noWrap
                    variant={checkIsActiveSub ? "subtitle2" : "body2"}
                    color="inherit"
                  >
                    {item.title && <FormattedMessage id={item.title} />}
                  </Typography>
                </Button>
              </LinkPM>
            );
          })}
        </Collapse>
      </Box>
    );
  }
  return (
    <>
      <LinkPM
        to={{
          pathname: data.path?.replace("/*", ""),
        }}
        style={{ display: "flex", flex: 1 }}
      >
        <Tooltip
          title={
            !open && (
              <Typography color="inherit" noWrap>
                {data.title && <FormattedMessage id={data.title} />}
              </Typography>
            )
          }
          placement="right"
        >
          <Button
            variant={checkIsActive ? "contained" : "text"}
            fullWidth
            disableElevation
            sx={{
              bgcolor: checkIsActive ? "primary.dark" : undefined,
              color: checkIsActive ? "common.white" : "text.primary",
              ":hover": {
                bgcolor: checkIsActive ? "primary.dark" : undefined,
              },
              minWidth: "-webkit-fill-available",
            }}
            style={{
              padding: isMobile ? "8px" : "8px 12px",
              justifyContent: "flex-start",
            }}
          >
            <Box height={24} marginRight={2}>
              {data.icon ? (
                <Box
                  color={checkIsActive ? "white" : "primary.main"}
                  height={24}
                >
                  {data.icon}
                </Box>
              ) : (
                <FiberManualRecordIcon
                  sx={{
                    color: checkIsActive ? "common.white" : "info.light",
                    transform: checkIsActive ? "scale(.6)" : "scale(.3)",
                    fontSize: 24,
                    transition: "all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  }}
                />
              )}
            </Box>
            <Typography
              noWrap
              variant={checkIsActive ? "subtitle2" : "body2"}
              color="inherit"
            >
              {data.title && <FormattedMessage id={data.title} />}
            </Typography>
          </Button>
        </Tooltip>
      </LinkPM>
    </>
  );
};

export default DefaultAsideItems;
