import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, BoxProps, ButtonBase, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import MyLink from "../../common/components/MyLink";
import useGeneralHook from "../../common/hook/useGeneralHook";
import { IRouteObject } from "../../common/utils";
import {
  setOpenAsideBar,
  setOpenAsideBarMobile,
} from "../../redux/cacheReducer";
import { AppState } from "../../redux/store";
import { LogoHorizontal } from "../../svg";
import DefaultAsideItems from "./DefaultAsideItems";
import ProfileBox from "./ProfileBox";
import { ASIDE_WIDTH } from "./constants";

const HEADER_HEIGHT = 0;
interface Props {
  prefix?: string;
  listRouter: IRouteObject[];
  footerNode?: React.ReactNode;
  sxContainer?: BoxProps["sx"];
}

const DefaultAside: React.FunctionComponent<Props> = (props) => {
  const { listRouter, prefix, footerNode, sxContainer } = props;
  const { isMobile, dispatch } = useGeneralHook();
  const location = useLocation();
  const { pathname } = location;
  const openAsideBarMobile = useSelector(
    (state: AppState) => state.cache.openAsideBarMobile
  );
  const openAsideBar = useSelector(
    (state: AppState) => state.cache.openAsideBar
  );
  const ASIDE_MIN_WIDTH = isMobile ? 0 : 64;
  const [hoverOpen, setHover] = React.useState(false);
  // const openMerge = isMobile ? openAsideBarMobile : openAsideBar || hoverOpen;
  const openMerge = isMobile ? openAsideBarMobile : openAsideBar;
  const openAsideTmp = isMobile ? openAsideBarMobile : openAsideBar;

  const [isResizing, setIsResizing] = React.useState(false);
  const [sidebarWidth, setSidebarWidth] = React.useState(ASIDE_WIDTH);

  const mappedRoutes = (list: IRouteObject[], parent?: IRouteObject) => {
    return list
      .map((one) => {
        if (one.hidden) {
          return undefined;
        }
        return {
          ...one,
          path: one.index
            ? parent?.path
            : parent?.path !== "/" && parent?.path
            ? `${parent?.path}/${one.path}`
            : one.path,
          children: one.children && mappedRoutes(one.children, one),
        };
      })
      ?.filter(Boolean);
  };
  const listRouterMapped = mappedRoutes(listRouter, {
    path: "/",
  });

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
    if (sidebarWidth < ASIDE_WIDTH && sidebarWidth > ASIDE_WIDTH / 2) {
      setSidebarWidth(ASIDE_WIDTH);
    } else if (sidebarWidth < ASIDE_WIDTH / 2) {
      dispatch(setOpenAsideBar(false));
      setSidebarWidth(ASIDE_WIDTH);
    }
  }, [dispatch, sidebarWidth]);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(mouseMoveEvent.clientX);
      }
    },
    [isResizing]
  );

  React.useEffect(() => {
    if (isMobile) {
      dispatch(setOpenAsideBarMobile(false));
    }
  }, [dispatch, isMobile, pathname]);

  React.useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <>
      <Box
        id="leftPanel"
        sx={{
          minWidth: openAsideTmp ? sidebarWidth : ASIDE_MIN_WIDTH,
        }}
      />

      <Box
        id="leftPanel"
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          ...sxContainer,
          width: openMerge ? sidebarWidth : ASIDE_MIN_WIDTH,
          height: "100vh",
          transition: isResizing ? "unset" : "width 0.3s",
          flexShrink: 0,
          zIndex: 200,
          borderRadius: 0,
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          top: HEADER_HEIGHT,
          left: 0,
          bottom: 0,
          willChange: "width",
        }}
        onMouseDown={(e) => e.preventDefault()}
      >
        {openAsideTmp && (
          <Box
            sx={{
              position: "absolute",
              right: -4,
              top: 0,
              bottom: 0,
              width: 8,
              zIndex: 2,
              cursor: "col-resize",
              resize: "horizontal",
              ":hover": {
                "&>div": { opacity: 1 },
              },
            }}
            onMouseDown={startResizing}
          >
            <Box
              sx={{
                bgcolor: "primary.main",
                opacity: 0,
                height: "100%",
                left: 4,
                position: "absolute",
                transition: "opacity 200ms ease 0s",
                width: 2,
              }}
            />
          </Box>
        )}
        <Box
          onMouseEnter={() => {
            openMerge && setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
        >
          <Tooltip
            title={<FormattedMessage id={openMerge ? "collapse" : "expand"} />}
            arrow
          >
            <ButtonBase
              sx={{
                borderRadius: "50%",
                position: "absolute",
                top: 40,
                right: -14,
                minWidth: 28,
                width: 28,
                height: 28,
                bgcolor: "background.paper",
                color: "text.primary",
                boxShadow: 4,
                border: 1,
                borderColor: "grey.200",
                zIndex: 3,
                transition: "all 0.3s",
                opacity: hoverOpen || !openAsideTmp ? 1 : 0,
                display: isMobile ? "none" : undefined,
                ":hover": {
                  bgcolor: "primary.main",
                  color: "common.white",
                },
              }}
              onClick={() => {
                dispatch(setOpenAsideBar());
                setHover(false);
              }}
            >
              <ArrowForwardIosIcon
                color="inherit"
                sx={{
                  fontSize: 14,
                  transform: openAsideTmp ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </ButtonBase>
          </Tooltip>
        </Box>
        <Box
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          overflow="hidden"
          borderRight={1}
          sx={{
            position: "relative",
            overflow: "hidden",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            borderColor: "divider",
            borderRight: 1,
          }}
        >
          <MyLink to="/" aria-label="Link to HomePage">
            <Typography
              sx={{
                height: 54,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                p: 1,
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#434141",
              }}
              component={"div"}
            >
              <LogoHorizontal style={{ height: 40 }} />
            </Typography>
          </MyLink>
          <PerfectScrollbar style={{ flex: 1 }}>
            <Box
              sx={{
                pb: 2,
                overflowY: "auto",
                overflowX: "hidden",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: isMobile ? 0.5 : 1,
              }}
            >
              {listRouterMapped?.map((v: IRouteObject, index: number) => (
                <DefaultAsideItems
                  key={index}
                  data={v}
                  pathname={pathname}
                  open={openMerge}
                  prefix={prefix}
                />
              ))}
            </Box>
          </PerfectScrollbar>
          {footerNode}
          {isMobile && (
            <Box
              sx={{
                pb: 4,
                width: "100%",
                display: "flex",
              }}
            >
              <ProfileBox
                sx={{
                  width: "100%",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DefaultAside;
