import {
  CheckRounded,
  EmailRounded,
  PhoneRounded,
  WhatsApp,
} from "@mui/icons-material";
import {
  Box,
  StepContent,
  StepIconProps,
  StepLabel,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { shallowEqual, useSelector } from "react-redux";
import { some } from "../../../../common/constants";
import { AppState } from "../../../../redux/store";
import { FormattedMessage } from "react-intl";

interface Props {
  data: some;
}
const CommunicationCard = (props: Props) => {
  const { data } = props;
  const currentInbox = useSelector(
    (state: AppState) => state.inbox.currentInbox,
    shallowEqual
  );

  function ColorlibStepIcon(props: StepIconProps) {
    const { className, title, id } = props;

    const icons: { [index: string]: React.ReactElement } = {
      call: <PhoneRounded sx={{ color: "primary.main" }} />,
      whatsapp: <WhatsApp sx={{ color: "success.main" }} />,
      email: <EmailRounded sx={{ color: "info.main" }} />,
    };

    return (
      <Box className={className}>
        {currentInbox?.id === id ? (
          <CheckRounded color="success" />
        ) : (
          icons[title || ""]
        )}
      </Box>
    );
  }

  return (
    <>
      <StepLabel
        StepIconComponent={ColorlibStepIcon}
        StepIconProps={{ title: data.type, id: data.id }}
      >
        <Typography variant="h6">
          {data?.created_at ? dayjs(data.created_at).format("LLL") : "-"}
        </Typography>
      </StepLabel>
      <StepContent>
        <Box sx={{ display: "flex" }}>
          <Box>
            <Typography
              flex={1}
              variant="subtitle2"
              component={"div"}
              className="flex items-center gap-1"
            >
              <FormattedMessage id="from" />
              :&nbsp;
              {data.type === "email" ? (
                <>{data?.from.email ? `${data?.from.email}` : ""}</>
              ) : (
                <>
                  {data?.from.name ? `${data?.from.name}` : ""}
                  <Typography
                    flex={1}
                    variant="caption"
                    component={"span"}
                    color={"textSecondary"}
                  >
                    {data?.from.mobile_number}
                  </Typography>
                </>
              )}
            </Typography>
            <Typography
              flex={1}
              variant="subtitle2"
              component={"div"}
              className="flex items-center gap-1"
            >
              <FormattedMessage id="to" />
              :&nbsp;
              {data.type === "email" ? (
                <>{data?.to.email ? `${data?.to.email}` : ""}</>
              ) : (
                <>
                  {data?.to.name ? `${data?.to.name}:` : ""}
                  <Typography
                    flex={1}
                    variant="caption"
                    component={"span"}
                    color={"textSecondary"}
                  >
                    {data?.to.mobile_number}
                  </Typography>
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </StepContent>
    </>
  );
};
export default CommunicationCard;
