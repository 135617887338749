import { Box, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store";
import SubmissionCard from "./SubmissionCard";

interface Props {}

const LeadDataBox: React.FunctionComponent<Props> = (props) => {
  const leadDetail =
    useSelector((state: AppState) => state.lead.leadDetail, shallowEqual) || {};

  if (!leadDetail.submissions || leadDetail.submissions.length === 0)
    return null;

  return (
    <Box className={"flex flex-col px-4 py-2 gap-0.5"}>
      <Typography variant="h6" flexShrink={0}>
        <FormattedMessage id="origins" />
      </Typography>
      <Box mb={2}>
        {leadDetail.submissions.map((item, index) => {
          return <SubmissionCard key={index} index={index} data={item} />;
        })}
      </Box>
    </Box>
  );
};
export default LeadDataBox;
