import {
  Action,
  combineReducers,
  configureStore,
  createAction,
  getType,
  ThunkAction,
} from "@reduxjs/toolkit";

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authFacebookReducer, { initialStateAuthFB } from "./authFacebookReducer";
import authReducer, { initialStateAuth } from "./authReducer";
import commonReducer, { initialStateCommon } from "./commonReducer";
import formReducer, { initialStateForm } from "./formReducer";
import inboxReducer, { initialStateInbox } from "./inboxReducer";
import intlReducer, { initialStateIntl } from "./intlReducer";
import leadReducer, { initialStateLead } from "./leadReducer";
import cacheReducer, { initialStateCache } from "./cacheReducer";
import selectionReducer, { initialStateSelection } from "./selectionReducer";

export const clearStoreAfterLogout = createAction("clearStoreAfterLogout");

const initialState = {
  inbox: initialStateInbox,
  form: initialStateForm,
  lead: initialStateLead,
  auth: initialStateAuth,
  authFB: initialStateAuthFB,
  intl: initialStateIntl,
  common: initialStateCommon,
  cache: initialStateCache,
  selection: initialStateSelection,
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["common", "lead", "form", "inbox"],
};

export default function createRootReducer() {
  const reducers = combineReducers({
    inbox: inboxReducer,
    form: formReducer,
    lead: leadReducer,
    auth: authReducer,
    authFB: authFacebookReducer,
    intl: intlReducer,
    common: commonReducer,
    cache: cacheReducer,
    selection: selectionReducer,
  });

  return (state, action: any) => {
    if (state && action.type === getType(clearStoreAfterLogout)) {
      return reducers(initialState, action);
    }
    return reducers(state, action);
  };
}

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export function makeStore() {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredPaths: [],
        },
      }),
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

let persistor = persistStore(store);

export { store, persistor };
