import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import ConnectedIntlProvider from "./intl/component/ConnectedIntlProvider";
import { MAIN_ROUTES } from "./layout/routes";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://394a55ac95da44929e23ca141f6cd5ae@o4505327130247168.ingest.sentry.io/4505327146631168",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    children: MAIN_ROUTES as any,
  },
]);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedIntlProvider
        formats={{
          number: {
            percentWith2Decimals: {
              style: "percent",
              maximumFractionDigits: 2,
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConnectedIntlProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
