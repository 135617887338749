import {
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Drawer,
  Portal,
  Tab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { RIGHT_BTN_ID } from "../../constant";
import CommunicationsTab from "./CommunicationsTab";

const TABS = [
  {
    label: "communications",
    component: <CommunicationsTab />,
  },
];

interface Props {}

const RightMenuBox: React.FunctionComponent<Props> = (props) => {
  const { isMobile, theme } = useGeneralHook();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState("communications");
  const [, setTick] = React.useState(false);

  React.useEffect(() => {
    if (isTablet) {
      setOpen(false);
    }
  }, [isTablet]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (document.getElementById(RIGHT_BTN_ID)) {
        setTick((old) => !old);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [isMobile, isTablet]);

  const content = React.useMemo(() => {
    return (
      <Box className="flex flex-col flex-1 overflow-hidden h-full">
        <TabContext value={tab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              top: 0,
              bgcolor: "background.paper",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              onClick={() => setOpen((old) => !old)}
              sx={{ mx: 1 }}
              onMouseEnter={(e) => {
                e.stopPropagation();
              }}
            >
              <KeyboardDoubleArrowRightOutlined />
            </Button>
            <TabList
              allowScrollButtonsMobile
              onChange={async (e, newValue) => {
                setTab(newValue);
              }}
              variant="scrollable"
              className="flex-1"
            >
              {TABS.map((tab) => {
                return (
                  <Tab
                    key={tab.label}
                    value={tab.label}
                    style={{ minWidth: 0 }}
                    label={
                      <Typography variant="subtitle1" color="inherit">
                        <FormattedMessage id={tab.label} />
                      </Typography>
                    }
                  />
                );
              })}
            </TabList>
          </Box>
          {TABS.map((tab, index) => {
            return (
              <TabPanel
                key={index}
                value={tab.label}
                sx={{ p: 0, flex: 1, overflow: "hidden" }}
              >
                {tab.component}
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    );
  }, [tab]);

  if (isMobile) {
    return (
      <>
        <Portal container={document.getElementById(RIGHT_BTN_ID)}>
          <Button
            variant="text"
            onClick={() => setOpen((old) => !old)}
            sx={{ mx: 1 }}
            onMouseEnter={(e) => {
              e.stopPropagation();
            }}
          >
            <KeyboardDoubleArrowLeftOutlined />
          </Button>
        </Portal>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          anchor="right"
          PaperProps={{ sx: { width: "85vw", borderRadius: 0 } }}
        >
          {content}
        </Drawer>
      </>
    );
  }

  return (
    <>
      {!open && (
        <Portal container={document.getElementById(RIGHT_BTN_ID)}>
          <Button
            variant="text"
            onClick={() => setOpen((old) => !old)}
            sx={{ mx: 1 }}
            onMouseEnter={(e) => {
              e.stopPropagation();
            }}
          >
            <KeyboardDoubleArrowLeftOutlined />
          </Button>
        </Portal>
      )}
      {open && (
        <Box
          sx={{
            width: 336,
            bgcolor: "background.paper",
          }}
        >
          {content}
        </Box>
      )}
    </>
  );
};
export default RightMenuBox;
