import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  paper: { minWidth: 460 },
  button: { minWidth: 110 },
}));

export interface ParamsPromptConfirmation {
  title?: React.ReactNode | string;
  content?: React.ReactNode | string;
  dialogProps?: Omit<DialogProps, "open">;
  okId?: React.ReactNode | string;
  cancelId?: React.ReactNode | string;
  warning?: boolean;
  open?: boolean;
  onAccept?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}
export interface ConfirmDialogParams {
  promptConfirmation: (setting?: ParamsPromptConfirmation) => Promise<unknown>;
}

const ConfirmDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  const [settings, setOptions] = useState<ParamsPromptConfirmation>(props);
  const [loading, setLoading] = useState(false);
  const [confirmDialogResolveRef, setConfirmDialogResolveRef] = React.useState<
    | {
        resolve?: (confirm: boolean) => void; //Đố biết sao phải để trong Object
      }
    | undefined
  >();
  const {
    warning,
    title,
    content,
    dialogProps,
    okId,
    cancelId,
    onAccept,
    onCancel,
  } = settings;

  const promptConfirmation = useCallback(
    async (setting?: Omit<ParamsPromptConfirmation, "children">) => {
      setting && setOptions((old) => ({ ...old, ...setting }));
      const confirmTmp = await new Promise((resolve) => {
        setConfirmDialogResolveRef({ resolve });
      });
      return confirmTmp;
    },
    []
  );

  const close = useCallback(() => {
    setConfirmDialogResolveRef(undefined);
  }, []);

  const onClose = useCallback(async () => {
    confirmDialogResolveRef?.resolve && confirmDialogResolveRef?.resolve(false);
    onCancel && (await onCancel());
    close();
  }, [close, confirmDialogResolveRef, onCancel]);

  const onOk = useCallback(async () => {
    confirmDialogResolveRef?.resolve && confirmDialogResolveRef?.resolve(true);
    try {
      setLoading(true);
      onAccept && (await onAccept());
    } finally {
      setLoading(false);
    }
    close();
  }, [close, confirmDialogResolveRef, onAccept]);

  const openDialog = confirmDialogResolveRef?.resolve !== undefined;

  useImperativeHandle(ref, () => ({
    promptConfirmation,
  }));

  return (
    <Dialog
      {...dialogProps}
      open={openDialog}
      classes={{ paper: classes.paper, ...dialogProps?.classes }}
      onClose={() => {
        onClose();
        setOptions(props);
      }}
      keepMounted={false}
    >
      <Box style={{ padding: 16, display: "flex", alignItems: "flex-end" }}>
        <Box flex={1}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
        </Box>
      </Box>
      <DialogContent style={{ padding: 16 }}>
        <Typography variant="body1" component="div">
          {content}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          variant="outlined"
          color="primary"
          classes={{ root: classes.button }}
          onClick={onClose}
          sx={{ minWidth: 100 }}
        >
          {typeof cancelId !== "string" && typeof cancelId !== "undefined" ? (
            cancelId
          ) : (
            <FormattedMessage id={cancelId || "cancel"} />
          )}
        </Button>
        <Box marginLeft={2}>
          <LoadingButton
            color={warning ? "error" : "primary"}
            variant="contained"
            classes={{ root: classes.button }}
            onClick={onOk}
            autoFocus
            sx={{ minWidth: 100 }}
            loading={loading}
          >
            {typeof okId !== "string" && typeof okId !== "undefined" ? (
              okId
            ) : (
              <FormattedMessage id={okId || "ok"} />
            )}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;
